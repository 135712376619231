import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSavedList } from "../../../../../../store/reducers/location";
import { axiosInstance } from "../../../../../../utils/axiosInstance";

const useDeleteAddress = (props) => {
  /*======================== Props ======================== */

  const { onClose, data } = props;

  /*======================== UseState ======================== */

  const [loading, setLoading] = useState(false);

  /*======================== Store ======================== */

  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.user?.customerID || "");

  /*======================== Handler ======================== */

  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();

    formData.append("user_id", user_id);
    formData.append("id_customer_address", data.id);

    try {
      const response = await axiosInstance.post(`address/delete`, formData);
      if (response.status === 200) {
        onClose();
        dispatch(getSavedList(user_id));
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  /*======================== Return ======================== */

  return {
    loading,
    handleSubmit,
  };
};

export default useDeleteAddress;
