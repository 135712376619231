import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { uniqueID } from '../../../utils/constants';
import { getOrderList } from '../../../store/reducers/order';

import Header from './Header';
import OrderTabs from './OrdersTabs';
import Loader from '../../Loader/index';
import NoOrders from './NoOrders';
import OrderList from './OrderList';
import { axiosInstance, encodeValue, sendAnalyticsPageView } from '../../../utils/helper';

var qs = require('qs');

function OrderHistory() {
	const history = useHistory();
	const { orderType = 'all' } = useParams();
	const dispatch = useDispatch();

	const customerID = useSelector((store) => store?.user?.customerID);

	const [trigerCart, setTrigerCart] = useState(false);
	const [loading, setLoading] = useState(false);

	const [displayCount, setDisplayCount] = useState(5);

	const all = useSelector((state) => state.order?.all);
	const received = useSelector((state) => state.order?.received);
	const delivering = useSelector((state) => state.order?.delivering);
	const processing = useSelector((state) => state.order?.processing);
	const completed = useSelector((state) => state.order?.completed);
	const canceled = useSelector((state) => state.order?.canceled);
	const orderAgainStatus = useSelector((state) => state.orderAgainStatus);

  useEffect(() => {
    dispatch(getOrderList({
      unquieid: uniqueID,
      customerID: encodeValue(customerID),
    })
  );
  }, []);

  useEffect(() => {
    sendAnalyticsPageView("order_history_session");
  }, []);

	useEffect(() => {
		if (!customerID) return;
		dispatch(getOrderList());
	}, [customerID]);

	const ratingChanged = (orderID, newRating) => {
		console.log('ratingChanged');
		const postObject = {
			unquieid: uniqueID,
			customerID: encodeValue(customerID),
			orderID: encodeValue(orderID),
			rating: newRating,
		};

		axiosInstance
			.post(`orders/createRating`, qs.stringify(postObject))
			.then((res) => {
				if (res.data.status === 'ok') {
					dispatch(getOrderList());
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleRemoveCart = async () => {
		console.log('handleRemoveCart');
		try {
			const postData = {
				unquieid: uniqueID,
				customerID: encodeValue(customerID),
			};
			const res = await axiosInstance.post(`cart/removeCart`, qs.stringify(postData));
			return res;
		} catch (error) {
			console.log('error: ', error);
		}
	};

	const handleOrderAgain = async (item) => {
		console.log('handleOrderAgain');
		try {
			const removeCart = await handleRemoveCart();
			console.log('removeCart: ', removeCart);
			if (removeCart?.data?.status !== 'ok') {
				return;
			}
			const postData = {
				unquieid: uniqueID,
				customerID: encodeValue(customerID),
				orderID: encodeValue(item?.order_primary_id),
			};
			const res = await axiosInstance.post(`orders/orderAgain`, qs.stringify(postData));
			console.log('res: ', res);
			if (res?.data?.status === 'ok') {
				history.push('/summary');
			}
		} catch (error) {
			console.log('error: ', error);
		}
	};

	const sateValChange = (field, value) => {
		if (field === 'trigerCart') {
			setTrigerCart(value);
		}
	};

	const loadMore = () => {
		setDisplayCount((prevCount) => prevCount + 5);
	};

	const orderList = useMemo(() => {
		if (orderType === 'all') {
			return all?.slice(0, displayCount);
		}
		if (orderType === 'waiting-payment') {
			return received;
		}
		if (orderType === 'on-process') {
			return processing;
		}
		if (orderType === 'delivering') {
			return delivering;
		}
		if (orderType === 'completed') {
			return completed;
		}
		if (orderType === 'canceled') {
			return canceled;
		}
	}, [orderType, all, received, processing, delivering, completed, canceled, displayCount]);

	return (
		<div>
			{loading && <Loader overlay />}
			<Header />
			<div className='content-body rel'>
				<OrderTabs orderType={orderType} all={all?.length} delivering={delivering?.length} onprogress={processing?.length} completed={completed?.length} canceled={canceled?.length} />
				{!orderList?.length ? (
					<NoOrders />
				) : (
					<>
						<OrderList orderList={orderList} orderType={orderType} ratingChanged={ratingChanged} orderAgain={handleOrderAgain} />
						{displayCount < all?.length && orderType === 'all' && (
							<div className='w-full flex justify-center py-2'>
								<button onClick={loadMore} className='button'>
									Load More
								</button>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
}

export default OrderHistory;
