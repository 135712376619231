import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  popular: [],
  recomendation: [],
  loading: false,
};

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const res = await axios.get('https://jsonplaceholder.typicode.com/photos')
  const data = await res.data
  return data
});

const search = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setSearch: (state, action) => {
			return { ...state, ...action.payload };
		},
		clearSearch: () => {
			return initialState;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUsers.pending, (state) => {
				state.status = 'pending';
			})
			.addCase(fetchUsers.fulfilled, (state, action) => {
				state.status = 'fulfilled';
				state.entities = action.payload;
			})
			.addCase(fetchUsers.rejected, (state, action) => {
				state.status = 'rejected';
				state.errors = action.error.message;
			});
	},
});

// Export actions and reducer
export const { setSearch, clearSearch } = search.actions;
export default search.reducer;
