import { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Loader from '../../components/Loader';
import { XMarkIcon, ArrowPathIcon, ChevronLeftIcon, ChevronRightIcon, ShareIcon } from '@heroicons/react/24/outline';
import useToast from '../../components/Toast/index';

const Tracking = () => {
	const history = useHistory();
	const location = useLocation();
	const { showToast } = useToast();
	const { trackingUrl, backPath } = location.state;

	const [isLoading, setIsLoading] = useState(false);

	const handleShare = (text = '') => {
		if (navigator.share) {
			navigator
				.share({
					title: 'Track Your Order',
					text: 'Check the live tracking of your order here:',
					url: text,
				})
				.then(() => showToast('info', 'URL shared successfully'))
				.catch((error) => {
					console.error('Failed to share:', error);
					showToast('error', 'Failed to share URL');
				});
		} else {
			navigator.clipboard
				.writeText(text)
				.then(() => {
					showToast('info', 'URL copied to clipboard');
				})
				.catch((err) => {
					console.error('Failed to copy: ', err);
					showToast('error', 'Failed to copy URL');
				});
		}
	};

	const handleReload = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 500);
	};

	return (
		<>
			{isLoading && <Loader overlay />}
			<div className='h-screen w-full flex justify-center items-center'>
				<div className='relative h-screen w-full max-w-lg'>
					<div className={`absolute mt-5 w-full max-w-lg bg-white border-b-1 border-solid border-gray-200 pb-1`}>
						<div className='flex flex-row justify-between mx-4'>
							<div className='flex justify-center items-center cursor-pointer'>
								<XMarkIcon className='h-8 w-8 text-gray-900 bg-white rounded-full p-1.5' strokeWidth={3} onClick={() => history.push(backPath ? backPath : '/')} />
								<div className='text-gray-900 font-bold text-base pl-1'>Live Tracking</div>
							</div>
							<div className='flex gap-4 items-center'>
								<ShareIcon className='h-5 w-5 font-bold text-gray-900' strokeWidth={3} onClick={() => handleShare(trackingUrl)} />
								<ArrowPathIcon className='h-6 w-6 text-gray-900' strokeWidth={2} onClick={handleReload} />
								<ChevronLeftIcon className='h-5 w-5 text-gray-400' strokeWidth={3} />
								<ChevronRightIcon className='h-5 w-5 text-gray-400' strokeWidth={3} />
							</div>
						</div>
					</div>
					<div className='pt-16 h-full'>{!isLoading && <iframe src={trackingUrl} title='description' className='w-full h-full'></iframe>}</div>
				</div>
			</div>
		</>
	);
};

export default Tracking;
