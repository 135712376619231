import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	myVoucherGroup: [],
  b2bVoucherGroup: [],

  myVoucherList: [],
  listUseMyVoucher: [],

  myVoucherNominal: 0,
  myVoucherTotal: 0,

  b2bVoucherList: [],
  listUseB2BVoucher: [],

	b2bVoucherTotal: 0,
  b2bVoucherNominal: 0,
  b2BVoucherPrice: 0,
  b2BVoucherSaved: 0,

  autoApplyMyVoucher: false,
  applyB2BVoucher: false,
  customApplyVoucher: false,
};

const voucher = createSlice({
	name: 'voucher',
	initialState,
	reducers: {
		setVoucher: (state, action) => {
			return { ...state, ...action.payload };
		},
		clearVoucher: () => {
			return initialState;
		},
	},
});

// Export actions and reducer
export const { setVoucher, clearVoucher } = voucher.actions;
export default voucher.reducer;
