import { useCallback } from "react";
import { useSelector } from "react-redux";

const useSearchList = () => {
  /*======================== UseState ======================== */

  const { cart } = useSelector((state) => state.search);

  /*======================== Handler ======================== */

  const handleTotalPrice = useCallback(() => {
    console.log('handleTotalPrice: ', cart);
    if (!cart) return 0;
    return Object.values(cart).reduce((total, { product, qty }) => {
      return total + product.price * qty;
    }, 0);
  }, [cart]);

  const handleTotalQuantity = useCallback(() => {
    console.log('handleTotalQuantity: ', cart);
    if (!cart) return 0;
    return Object.values(cart).reduce((total, { qty }) => {
      return total + qty;
    }, 0);
  }, [cart]);

  /*======================== Return ======================== */

  return {
    handleTotalPrice,
    handleTotalQuantity,
  };
};

export default useSearchList;
