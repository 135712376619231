/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSavedList,
  clearSearch,
  getRecentList,
} from "../../../../../store/reducers/location";
import homeIcon from "../../../../../common/images/revamp/icon/home-gray.svg";
import officeIcon from "../../../../../common/images/revamp/icon/office-gray.svg";
import otherIcon from "../../../../../common/images/revamp/icon/tag-gray.svg";
import { setUser } from "../../../../../store/reducers/user";

const useDeliverySavedAddress = (props) => {
  /*======================== Props ======================== */

  const { handleCloseDelivery } = props;

  /*======================== UseState ======================== */

  const [showOption, setShowOption] = useState("");
  const [deleteForm, setDeleteForm] = useState({
    isOpen: false,
    data: undefined,
  });
  const [form, setForm] = useState({
    isOpen: false,
    data: undefined,
    type: "",
  });

  /*======================== Store ======================== */

  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.user?.customerID || "");
  const lat = useSelector((state) => state.user?.latitude || "");
  const lng = useSelector((state) => state.user?.longitude || "");
  const address = useSelector((state) => state.user?.address || "");
  const { savedIsOpen, savedInitialForm, savedSearchResult, savedData } =
    useSelector((state) => state.location);

  /*======================== Handler ======================== */

  const handleForm = (type, item) => {
    console.log("item", item);
    switch (type) {
      case "add":
        setForm({
          isOpen: true,
          data: {
            address,
            lat: Number(lat),
            lng: Number(lng),
          },
          type: "add",
        });
        break;

      case "edit":
        setForm({
          isOpen: true,
          data: {
            addressName: item.address_name,
            addressDetail: item.address_detail,
            type: item.type,
            contactName: item.contact_name,
            contactNumber: item.contact_number,
            lat: Number(item.latitude),
            lng: Number(item.longitude),
            id_customer_address: item.id,
          },
          type: "edit",
        });
        break;

      default:
        setForm({
          isOpen: false,
          data: undefined,
          type: "",
        });
        break;
    }
  };
  const handleIcon = (type) => {
    switch (type) {
      case "home":
        return homeIcon;
      case "office":
        return officeIcon;
      default:
        return otherIcon;
    }
  };
  const handleSelectLocation = (location) => {
    const {
      address,
      latitude,
      longitude,
      country,
      state,
      city,
      village,
      district,
      postal_code,
    } = location;

    dispatch(
      setUser({
        address,
        latitude,
        longitude,
        country,
        state,
        city,
        village,
        district,
        postalCode: postal_code,
      })
    );
    handleCloseDelivery();
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    dispatch(getSavedList(user_id));
    dispatch(getRecentList(user_id))
  }, []);

  useEffect(() => {
    if (savedIsOpen && savedInitialForm) {
      setForm({
        isOpen: true,
        data: {
          ...savedInitialForm,
          lat: Number(savedSearchResult ? savedSearchResult.lat : lat),
          lng: Number(savedSearchResult ? savedSearchResult.lng : lng),
        },
        type: savedInitialForm.formType,
      });
      dispatch(clearSearch());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedIsOpen, savedInitialForm]);

  /*======================== Return ======================== */

  return {
    showOption,
    deleteForm,
    form,
    savedData,
    setShowOption,
    setDeleteForm,
    handleForm,
    handleIcon,
    handleSelectLocation,
  };
};

export default useDeliverySavedAddress;
