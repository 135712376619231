import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { setLocation } from "../../../../store/reducers/location";
import { setUser } from "../../../../store/reducers/user";

const useDeliveryLocationDetail = (props) => {
  /*======================== Props ======================== */

  const { handleCloseDelivery } = props;
  const {
    ready: isAutocompleteReady,
    value: search,
    suggestions: { loading, data: searchData },
    setValue: setSearch,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 500,
    requestOptions: { types: [] },
  });

  /*======================== Store ======================== */

  const dispatch = useDispatch();
  const { savedIsOnSearch } = useSelector((state) => state.location);

  /*======================== Handler ======================== */

  const handleFormatingAddress = ({
    lat,
    lng,
    formatted_address,
    address_components,
  }) => {
    let country = "";
    let state = "";
    let city = "";
    let village = "";
    let district = "";
    let postalCode = "";

    address_components?.forEach((item) => {
      if (item.types.includes("country")) {
        country = item.long_name;
      }
      if (item.types.includes("administrative_area_level_1")) {
        state = item.long_name;
      }
      if (item.types.includes("administrative_area_level_2")) {
        city = item.long_name;
      }
      if (item.types.includes("administrative_area_level_3")) {
        village = item.long_name;
      }
      if (item.types.includes("administrative_area_level_4")) {
        district = item.long_name;
      }
      if (item.types.includes("postal_code")) {
        postalCode = item.long_name;
      }
    });
    dispatch(
      setUser({
        address: formatted_address,
        latitude: lat,
        longitude: lng,
        country,
        state,
        city,
        village,
        district,
        postalCode,
      })
    );
    setSearch("");
    handleCloseDelivery();
  };

  const handleSelectLocation = (location) => {
    getGeocode({ placeId: location.place_id }).then((results) => {
      const result = results[0];
      const { lat, lng } = getLatLng(result);
      if (savedIsOnSearch) {
        setSearch("");
        dispatch(
          setLocation({
            savedIsOpen: true,
            savedIsOnSearch: false,
            savedSearchResult: {
              lat,
              lng,
            },
          })
        );
        // setSavedSearch({
        //   isOnSearch: false,
        //   address: result?.formatted_address || "",
        //   lat,
        //   lng,
        // });
      } else {
        handleFormatingAddress({
          lat,
          lng,
          formatted_address: result?.formatted_address || "",
          address_components: result?.address_components || {},
        });
      }
    });
  };

  const handleCancelSearchSaved = () => {
    dispatch(
      setLocation({
        savedIsOpen: true,
        savedIsOnSearch: false,
        savedSearchResult: null,
      })
    );
  };
  // const handleSearchToSave = () => {
  //   setSavedSearch({
  //     isOnSearch: true,
  //     address: null,
  //     lat: null,
  //     lng: null,
  //   });
  // };

  /*======================== Return ======================== */

  return {
    isAutocompleteReady,
    search,
    searchData,
    loading,
    setSearch,
    clearSuggestions,
    handleSelectLocation,
    handleCancelSearchSaved,
  };
};

export default useDeliveryLocationDetail;
