import { useState } from "react";
import { useDispatch } from "react-redux";
import { SET_SEARCH_CART } from "../../../../actions/searchAction";

const useSearchProduct = () => {
  /*======================== UseState ======================== */

  const [selected, setSelected] = useState({});
  const dispatch = useDispatch();

  /*======================== Handler ======================== */

  const handleSelected = (type, product) => {
    dispatch({ type: SET_SEARCH_CART, payload: { type, product } });

    setSelected((prevSelected) => {
      const newSelected = { ...prevSelected };

      if (type === "add") {
        if (newSelected[product.id]) {
          newSelected[product.id].qty += 1;
        } else {
          newSelected[product.id] = { product, qty: 1 };
        }
      }

      if (type === "remove") {
        if (newSelected[product.id]) {
          if (newSelected[product.id].qty > 1) {
            newSelected[product.id].qty -= 1;
          } else {
            delete newSelected[product.id];
          }
        }
      }

      return newSelected;
    });
  };

  /*======================== Return ======================== */

  return { selected, handleSelected };
};

export default useSearchProduct;
