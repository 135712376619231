import { useState } from "react";

const useDeliveryLocation = () => {
  /*======================== UseState ======================== */

  const [openDetail, setOpenDetail] = useState(false);

  /*======================== Handler ======================== */

  const handleOpenDetail = () => {
    setOpenDetail(!openDetail);
  };

  /*======================== Return ======================== */

  return {
    openDetail,
    handleOpenDetail,
  };
};

export default useDeliveryLocation;
