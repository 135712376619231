import React, { useMemo } from 'react';
import { formatRupiah } from '../../../utils/helper';
import TooltipInfo from '../../TooltipInfo';
import { TicketDiscountIcon } from '../../../utils/svg';

const PaymentSummary = ({ orderDetails = {} }) => {

	const tooltipOtherFee = useMemo(() => {
		const result = [];
		if (orderDetails?.packagingFee) {
			result?.push({
				name: 'Packaging Fee',
				desc: 'Charge for packaging materials ensuring safe and hygienic food delivery.',
				total: null,
				nominal: orderDetails?.packagingFee,
			});
		}
		if (orderDetails?.platformFee) {
			result?.push({
				name: 'Platform Fee',
				desc: 'Fee for facilitating transactions via our integrated food delivery platform.',
				total: null,
				nominal: orderDetails?.platformFee,
			});
		}
		if (orderDetails?.request_post_datas?.paymentFee) {
			result?.push({
				name: 'Service Fee',
				desc: 'Service fee are applied to provide the best service for Ufood customers.',
				total: null,
				nominal: orderDetails?.request_post_datas?.paymentFee,
			});
		}
		return result;
	}, [orderDetails]);

	const otherFee = useMemo(() => {
		return Number(orderDetails?.packagingFee ?? 0) + Number(orderDetails?.platformFee ?? 0) + Number(orderDetails?.request_post_datas?.paymentFee ?? 0);
	}, [orderDetails]);

	const totalB2BVoucherNominal = (voucherList = []) => {
		if (!voucherList.length) return 0;
		return voucherList.reduce((total, voucher) => total + voucher.voucher_nominal, 0);
	};

	const totalNominalB2BVoucher = useMemo(() => {
		return totalB2BVoucherNominal(orderDetails?.request_post_datas?.b2b_voucher) || 0;
	}, [orderDetails]);

	const totalB2BVoucherPrice = (voucherList) => {
		if (!voucherList?.length) return 0;
		return voucherList.reduce((total, voucher) => total + voucher.voucher_price_user, 0);
	};

	const totalPriceB2BVoucher = useMemo(() => {
		return totalB2BVoucherPrice(orderDetails?.request_post_datas?.b2b_voucher) || 0;
	}, [orderDetails]);

	const totalMyVoucherNominal = (voucherList) => {
		if (!voucherList?.length) return 0;
		return voucherList.reduce((total, voucher) => total + voucher.amount, 0);
	};

	const maxNominalVoucher = useMemo(() => {
		return totalMyVoucherNominal(orderDetails?.voucher) || 0;
	}, [orderDetails]);

	const groupMyForTooltip = (myVoucherList = []) => {
		if (!myVoucherList?.length) return [];

		const result = [];
		myVoucherList?.forEach((voucher) => {
			const existingVoucher = result.find((v) => v.price === voucher.amount);
			if (existingVoucher) {
				existingVoucher.total += 1;
			} else {
				result.push({
					name: voucher?.name,
					desc: 'Using the available voucher in my voucher.',
					total: 1,
					price: voucher?.amount,
				});
			}
		});

		return result;
	};

	const groupB2BForTooltip = (b2bVoucherList = []) => {
		if (!b2bVoucherList?.length) return [];

		const result = [];
		b2bVoucherList?.forEach((voucher) => {
			const existingVoucher = result.find((v) => v.nominal === voucher.voucher_nominal);

			if (existingVoucher) {
				existingVoucher.total += voucher?.voucher_qty;
			} else {
				result.push({
					name: voucher?.voucher_code,
					desc: 'Purchase voucher available at related merchants.',
					total: 1,
					nominal: voucher?.voucher_nominal,
				});
			}
		});

		return result;
	};

	const tooltipVoucherList = useMemo(() => {
		const groupMyVoucher = groupMyForTooltip(orderDetails?.voucher);
		const groupB2BVoucher = groupB2BForTooltip(orderDetails?.request_post_datas?.b2b_voucher);
		return [...groupMyVoucher, ...groupB2BVoucher];
	}, [orderDetails]);

	const strikeOutTotalPayment = useMemo(() => {
		return Number(orderDetails?.subTotal) + Number(otherFee) + Number(orderDetails?.deliveryFee);
	}, [orderDetails, otherFee]);

	return (
		<div className='px-4 mt-4 mb-32'>
			<h3 className='text-base font-semibold text-gray-900'>Payment Summary</h3>
			<div className='mt-4 border-1 border-solid border-gray-300 px-3 py-4 rounded-lg'>
				<div>
					<div className={`flex justify-between items-center`}>
						<div className='text-gray-900 text-xs font-normal'>F&B Price</div>
						<div className='text-xs font-normal flex'>
							<div className='text-gray-700 pl-2'>{formatRupiah(orderDetails?.subTotal ?? 0)}</div>
						</div>
					</div>
          <div className={`${orderDetails?.tax ? 'hidden' : 'flex'} justify-between items-center mt-1`}>
						<div className='text-gray-900 text-xs font-normal'>Tax</div>
						<div className='text-xs font-normal flex'>
							<div className='text-gray-700 pl-2'>{formatRupiah(orderDetails?.tax ?? 0)}</div>
						</div>
					</div>
          <div className={`${!orderDetails?.pb1 ? 'hidden' : 'flex'} justify-between items-center mt-1`}>
						<div className='text-gray-900 text-xs font-normal'>PB1</div>
						<div className='text-xs font-normal flex'>
							<div className='text-gray-700 pl-2'>{formatRupiah(orderDetails?.pb1 ?? 0)}</div>
						</div>
					</div>
					<div className={`${!orderDetails?.deliveryCharge ? 'hidden' : 'flex'} justify-between items-center mt-1`}>
						<div className='text-gray-900 text-xs font-normal'>Delivery</div>
						<div className='text-xs font-normal flex'>
							<div className='text-gray-700 pl-2'>{formatRupiah(Number(orderDetails?.deliveryCharge) + Number(orderDetails?.deliveryFee) ?? 0)}</div>
						</div>
					</div>
					<div className={`${!otherFee ? 'hidden' : 'flex'} justify-between items-center mt-1`}>
						<div className='text-gray-900 text-xs font-normal flex items-center'>
							Other Fees
							<TooltipInfo title="What's Included?" items={tooltipOtherFee} />
						</div>
						<div className='text-xs font-normal flex'>
							<div className='text-gray-700 pl-2'>{formatRupiah(otherFee ?? 0)}</div>
						</div>
					</div>
					<div className={`${!totalNominalB2BVoucher ? 'hidden' : 'flex'} justify-between items-center mt-1`}>
						<div className='text-gray-900 text-xs font-normal'>Buy Voucher</div>
						<div className='text-xs font-normal flex'>
							<div className='text-gray-400 font-thin line-through'>{formatRupiah(totalNominalB2BVoucher)}</div>
							<div className='text-gray-700 pl-2'>{formatRupiah(totalPriceB2BVoucher)}</div>
						</div>
					</div>
					<div className={`${!maxNominalVoucher ? 'hidden' : 'flex'} justify-between items-center mt-2`}>
						<div className='text-gray-900 text-xs font-normal flex items-center'>
							<TicketDiscountIcon className='h-6 w-6 mr-1' />
							Voucher Applied
							<TooltipInfo title='Voucher Applied' items={tooltipVoucherList} />
						</div>
						<div className='text-xs font-normal flex'>
							<div className='text-rose-violet pl-2'>-{formatRupiah(maxNominalVoucher || 0)}</div>
						</div>
					</div>
					<div className='border-b-1 border-solid pb-2 border-gray-300' />
					<div className='flex justify-between items-center mt-2'>
						<div className='text-gray-900 text-sm font-extrabold items-center'>Total Payment</div>
						<div className='text-sm font-extrabold flex'>
							<div className={`${!maxNominalVoucher && 'hidden'} text-gray-400 font-thin line-through`}>{formatRupiah(strikeOutTotalPayment)}</div>
							<div className='text-gray-900 pl-2'>{formatRupiah(orderDetails.totalAmount || 0)}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PaymentSummary;
