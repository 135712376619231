import {
  ORDER_AGAIN_REQUEST,
  ORDER_AGAIN_SUCCESS,
  ORDER_AGAIN_FAILURE,
  RESET_ORDER_AGAIN_STATUS,
} from "./index";

export const orderAgainRequest = (orderID) => ({
  type: ORDER_AGAIN_REQUEST,
  orderID,
});

export const orderAgainSuccess = () => ({
  type: ORDER_AGAIN_SUCCESS,
});

export const orderAgainFailure = (error) => ({
  type: ORDER_AGAIN_FAILURE,
  error,
});

export const resetOrderAgainStatus = () => ({
  type: RESET_ORDER_AGAIN_STATUS,
});
