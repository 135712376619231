import useDeliveryRecent from "./useDeliveryRecent";
import locationIcon from "../../../../../common/images/revamp/icon/location.svg";

const DeliveryRecent = (props) => {
  /*======================== Props ======================== */

  const { recentLocation, handleSelectLocation } = useDeliveryRecent(props);

  /*======================== Return ======================== */

  return (
    <div className="px-4 h-80">
      <p className="text-black37 font-semibold text-sm mb-4">Recent</p>
      <div className="space-y-2.5">
        {recentLocation?.map((location, index) => (
          <div
            key={index}
            className="[&:not(:last-child)]:border-b border-solid border-grayD3 p-2.5 flex items-center gap-2.5"
            onClick={() => handleSelectLocation(location)}
          >
            <img src={locationIcon} alt="location" className="w-4.5 h-4.5" />
            <div className="space-y-0.5">
              {/* <p className="text-black37 text-sm font-semibold">
                {location.name}
              </p> */}
              <p className="text-gray90 text-xxs">
                {location.address}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeliveryRecent;
