import React, { useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import { ReactComponent as StarFull } from '../../../common/images/star.svg';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import { uniqueID } from '../../../utils/constants';
import { axiosInstance, encodeValue } from '../../../utils/helper';

function RatingComponent({ orderID, orderType, orderRating }) {
	const dispatch = useDispatch();
	const customerID = useSelector((store) => store?.user?.customerID);
	const [ratingValue, setRatingValue] = useState(0);
	const [readonly, setReadonly] = useState(orderRating !== '0.00');

	const handleRating = (rate) => {
		var postObject = {
			unquieid: uniqueID,
			customerID: encodeValue(customerID),
			orderID: encodeValue(orderID),
			rating: rate,
		};

		console.log('postObject: ', postObject);

		axiosInstance
			.post('orders/createRating', qs.stringify(postObject))
			.then((res) => {
				console.log('res', res);
				if (res.data.status === 'ok') {
					setRatingValue(rate);
					setReadonly(true);
				}
			})
			.catch((error) => {
				console.log('error', error);
			});
	};

	return (
		<div
			className='w-full flex flex-col items-center justify-center py-3'
			style={{
				border: '1px solid #D3D3D3',
				borderRadius: '6px',
				gap: '12px',
				marginBottom: '20px',
			}}>
			<h3 className='text-sm font-semibold'>Let's give a rating!</h3>
			<Rating
				initialValue={parseFloat(orderRating || ratingValue)}
				readonly={readonly}
				onClick={handleRating}
				transition
				allowFraction
				emptyIcon={<StarFull width={36} height={36} style={{ display: 'inline' }} />}
				fillIcon={<StarFull width={36} height={36} style={{ display: 'inline' }} />}
			/>
		</div>
	);
}

export default RatingComponent;
