import React from 'react';
/* import { render } from "react-dom"; */
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor } from './store/index';
import { PersistGate } from 'redux-persist/integration/react';

import './common/css/font-awesome.min.css';
import './common/css/bootstrap.min.css';
import './common/css/style.css';
import './common/css/responsive.css';
import './common/css/slick.css';
import RouteConfig from './router/Router';
import './tailwind.css';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<Router>
				<RouteConfig />
			</Router>
		</PersistGate>
	</Provider>,
);
