import locationPurpleIcon from '../../../../../common/images/revamp/icon/location-purple.svg';
import UFInput from '../../../../Input/UFInput';
import { twMerge } from 'tailwind-merge';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { centerLocation } from '../../../../../utils/constants';
import { useState, useEffect, useCallback } from 'react';
import homeGrayIcon from '../../../../../common/images/revamp/icon/home-gray.svg';
import homePurpleIcon from '../../../../../common/images/revamp/icon/home-purple.svg';
import officeGrayIcon from '../../../../../common/images/revamp/icon/office-gray.svg';
import officePurpleIcon from '../../../../../common/images/revamp/icon/office-purple.svg';
import otherGrayIcon from '../../../../../common/images/revamp/icon/tag-gray.svg';
import otherPurpleIcon from '../../../../../common/images/revamp/icon/tag-purple.svg';
import { getGeocode } from 'use-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { getSavedList, setLocation as setLocationRedux } from '../../../../../store/reducers/location';
import { axiosInstance, sendAnalyticsCustomEvent } from '../../../../../utils/helper';
import { sendAnalyticsPageView } from '../../../../../utils/helper';

const FormAddress = (props) => {
	console.log('FormAddress');
	/*======================== UseState ======================== */

	const { data, type, onClose } = props;
	const [loading, setLoading] = useState(false);
	const [formValue, setFormValue] = useState({
		addressName: '',
		addressDetail: '',
		type: 'home',
		contactName: '',
		contactNumber: '',
		id_customer_address: '',
	});
	const [location, setLocation] = useState({
		address: null,
		name: null,
		lat: data?.lat ?? null,
		lng: data?.lng ?? null,
	});

	const [errors, setErrors] = useState('');

	/*======================== Props ======================== */

	let center = null;
	let zoom = 15;
	if (location?.lat && location?.lng) {
		center = {
			lat: location?.lat,
			lng: location?.lng,
		};
	} else {
		center = centerLocation;
		zoom = 4;
	}

	/*======================== Store ======================== */

	const dispatch = useDispatch();
	const user_id = useSelector((state) => state.user?.customerID || '');

	/*======================== Handler ======================== */

	const onLoadMap = useCallback(() => {
		setTimeout(() => {
			const elementsToHide = document.querySelectorAll('.gmnoprint');
			elementsToHide.forEach((el) => (el.style.display = 'none'));
		}, 1000);
	}, []);

	const handleDragMap = async (data) => {
		console.log('handleDragMap');
		const lat = data?.latLng?.lat();
		const lng = data?.latLng?.lng();
		getGeocode({ location: { lat, lng } }).then((results) => {
			const result = results[0];
			handleFormatingAddress({
				lat,
				lng,
				formatted_address: result?.formatted_address || '',
				address_components: result?.address_components || {},
			});
		});
	};

	const handleFormatingAddress = ({ lat, lng, formatted_address, address_components }) => {
		let country = '';
		let state = '';
		let city = '';
		let village = '';
		let district = '';
		let postalCode = '';

		address_components?.forEach((item) => {
			if (item.types.includes('country')) {
				country = item.long_name;
			}
			if (item.types.includes('administrative_area_level_1')) {
				state = item.long_name;
			}
			if (item.types.includes('administrative_area_level_2')) {
				city = item.long_name;
			}
			if (item.types.includes('administrative_area_level_3')) {
				village = item.long_name;
			}
			if (item.types.includes('administrative_area_level_4')) {
				district = item.long_name;
			}
			if (item.types.includes('postal_code')) {
				postalCode = item.long_name;
			}
		});
		const dataAddress = {
			address: formatted_address,
			name: district || village,
			lat,
			lng,
			country,
			state,
			city,
			village,
			district,
			postalCode,
		};
		setLocation(dataAddress);
	};
	const handleOnClickMap = async (data) => {
		const lat = data?.latLng?.lat();
		const lng = data?.latLng?.lng();
		const place_id = data?.placeId;
		if (lat && lng && place_id) {
			getGeocode({ placeId: place_id }).then((results) => {
				const result = results[0];
				handleFormatingAddress({
					lat,
					lng,
					formatted_address: result?.formatted_address || '',
					address_components: result?.address_components || {},
				});
			});
		}
	};
	const handleFormValue = (type, value) => {
		switch (type) {
			case 'contactNumber':
				// const sanitizedValue = value.replace(/\D|^0+/g, "");
				const sanitizedValue = value.replace(/\D+/g, '');
				setFormValue({
					...formValue,
					contactNumber: sanitizedValue,
				});
				break;
			default:
				setFormValue({
					...formValue,
					[type]: value,
				});
				break;
		}
	};
	const handleValidate = (type) => {
		if (errors && type === 'addressName') {
			return errors;
		} else if (errors && type === 'contactName') {
			return errors;
		} else if (errors && type === 'contactNumber') {
			return errors;
		}

		switch (true) {
			case type === 'addressName' && formValue.addressName.length > 0 && formValue.addressName.length < 5:
				return 'Your address name should contain at least 5 characters';

			case type === 'contactNumber' && formValue.contactNumber.length > 0 && formValue.contactNumber.length < 9:
				return 'Your phone number should contain at least 9 digits';

			case type === 'contactName' && formValue.contactName.length > 0 && !/^[a-zA-Z\s]*$/.test(formValue.contactName):
				return 'Your contact name should contain only alphabetical characters';

			default:
				return '';
		}
	};
	const handleDisableSubmit = () => {
		if (errors) return false;

		if (loading) return true;
		if (formValue.addressName.length >= 5 && formValue.contactName.length > 0 && formValue.contactNumber.length > 9) {
			return false;
		}
		return true;
	};
	const handleSubmit = async () => {
		setErrors('');
		setLoading(true);
		const payload = {
			user_id,
			...location,
			...formValue,
			latitude: location.lat,
			longitude: location.lng,
		};
		if (type === 'add') {
			delete payload.id_customer_address;
		}

		const formData = new FormData();

		console.log('payload: ', payload);
		// Dynamically append each key-value pair from the object to formData
		Object.entries(payload).forEach(([key, value]) => {
			formData.append(key, value);
		});

		sendAnalyticsCustomEvent('homepage_delivery_to', payload);

		try {
			const response = await axiosInstance.post(`address/${type}`, formData);
			if (response.status === 200 && response.data.status === 'success') {
				onClose();
				dispatch(getSavedList(user_id));
			} else {
				if (response?.data?.message) {
					setErrors(response.data.message || {});
				}
			}
		} catch (error) {
			console.log('error', error.response.data.message);

			if (error?.response && error?.response?.data?.message) {
				setErrors(error.response.data.message || {});
			}
		} finally {
			setLoading(false);
		}
	};

	const handleSearch = () => {
		dispatch(
			setLocationRedux({
				savedIsOnSearch: true,
				savedInitialForm: { ...formValue, formType: type },
			}),
		);
		onClose();
	};

	/*======================== UseEffect ======================== */

	useEffect(() => {
		if (data) {
			setFormValue({
				addressName: data.addressName || '',
				addressDetail: data.addressDetail || '',
				type: data.type || 'home',
				contactName: data.contactName || '',
				contactNumber: data.contactNumber || '',
				id_customer_address: data.id || data.id_customer_address || '',
			});
			if (!data?.lat && !data?.lng) return;
			getGeocode({ location: { lat: data?.lat, lng: data?.lng } }).then((results) => {
				const result = results[0];
				handleFormatingAddress({
					lat: data.lat,
					lng: data.lng,
					formatted_address: result?.formatted_address || '',
					address_components: result?.address_components || {},
				});
			});
		}
	}, [data]);

	/*======================== Others ======================== */

	const mapOptions = {
		disableDefaultUI: true,
		zoomControl: false,
		mapTypeControl: false,
		fullscreenControl: false,
		streetViewControl: false,
		rotateControl: false,
	};
	const typeOptions = [
		{
			id: 'save_address_select_home',
			label: 'Home',
			value: 'home',
			iconInactive: homeGrayIcon,
			iconActive: homePurpleIcon,
		},
		{
			id: 'save_address_select_office',
			label: 'Office',
			value: 'office',
			iconInactive: officeGrayIcon,
			iconActive: officePurpleIcon,
		},
		{
			id: 'save_address_select_other',
			label: 'Other',
			value: 'other',
			iconInactive: otherGrayIcon,
			iconActive: otherPurpleIcon,
		},
	];

	/*======================== Return ======================== */

	useEffect(() => {
		sendAnalyticsPageView('save_address_session');
	}, []);

	return (
		<div className={`pointer-events-auto z-[9999999] fixed top-0 h-screen w-screen !-mt-[calc(100vh-(100vh-(100vh/10)))] left-0 bg-white overflow-y-auto`}>
			<div className='absolute top-4 left-4 grid place-content-center cursor-pointer z-5' onClick={onClose}>
				<ArrowLeftIcon className='h-8 w-8 text-gray-900 bg-white rounded-full p-2 shadow-custom' />
			</div>

			<div className='flex flex-col'>
				{/* Location */}
				<GoogleMap
					mapContainerClassName='h-[240px] w-full'
					clickableIcons={!loading}
					center={center}
					zoom={zoom}
					onClick={(e) => {
						handleOnClickMap(e);
					}}
					onLoad={onLoadMap}
					// onUnmount={onUnmount}
					options={mapOptions}>
					{centerLocation && location && (
						<Marker
							position={location}
							draggable={!loading}
							onDragEnd={(e) => {
								handleDragMap(e);
							}}
						/>
					)}
				</GoogleMap>
				<div className='w-full h-full space-y-4 [&>*]:px-4 py-4  grow'>
					<div className='flex justify-between items-center'>
						<span className='text-black37 font-semibold text-sm'>Add an address</span>
						<button
							id='save_address_search'
							className='capitalize border border-solid border-ufdPurple text-ufdPurple py-1.5 px-3 text-center text-xs font-semibold rounded hover:bg-ufdPurple hover:text-white transition-all disabled:cursor-not-allowed disabled:opacity-50'
							onClick={handleSearch}
							disabled={loading}>
							Search
						</button>
					</div>

					<div className='flex gap-2.5 items-start'>
						<img src={locationPurpleIcon} alt='location-purple' className='w-4 h-4' />
						<div className='grow'>
							<div className='space-y-0.5'>
								<p className='text-black37 text-sm font-semibold'>{location.name}</p>
								<p className='text-black37 text-xxs'>{location.address}</p>
							</div>
						</div>
					</div>

					<div className='h-2 w-full bg-grayf4 px-0'></div>

					<div className='px-4 space-y-2'>
						<UFInput
							id='save_address_fill_name'
							label='Address Name'
							required
							type='text'
							placeholder='e.g. Home, Office'
							value={formValue.addressName}
							errorMessage={handleValidate('addressName')}
							onChange={(event) => handleFormValue('addressName', event.target.value)}
							maxLength={25}
							disabled={loading}
						/>
						<UFInput
							id='save_address_fill_detail'
							label='Address Details'
							optional
							type='text'
							placeholder='e.g. Floor, unit number'
							value={formValue.addressDetail}
							onChange={(event) => handleFormValue('addressDetail', event.target.value)}
							maxLength={41}
							disabled={loading}
						/>

						<div className='grid grid-cols-3 gap-4 justify-between'>
							{typeOptions.map((item, index) => (
								<div
									id={item?.id}
									key={index}
									className={twMerge(
										'rounded-md border border-solid border-grayD3 bg-white text-black37 py-2 px-[14px] flex gap-2.5 justify-center items-center',
										item.value === formValue.type && 'text-ufdPurple border-ufdPurple',
										loading && 'pointer-events-none opacity-50',
									)}
									onClick={() => handleFormValue('type', item.value)}>
									<img src={item.value === formValue.type ? item.iconActive : item.iconInactive} alt={item.value} className='w-4.5 h-4.5' />
									<span className='font-semibold text-xs'>{item.label}</span>
								</div>
							))}
						</div>

						<UFInput
							id='save_address_fill_contact_name'
							label='Contact Name'
							required
							type='text'
							placeholder='e.g. Jhon doe'
							value={formValue.contactName}
							errorMessage={handleValidate('contactName')}
							onChange={(event) => handleFormValue('contactName', event.target.value)}
							maxLength={25}
							disabled={loading}
						/>

						<UFInput
							id='save_address_fill_contact_phone'
							label='Contact Number'
							required
							type='tel'
							placeholder='e.g. 081234567890'
							value={formValue.contactNumber}
							errorMessage={handleValidate('contactNumber')}
							onChange={(event) => handleFormValue('contactNumber', event.target.value)}
							maxLength={15}
							disabled={loading}
						/>

						<button
							id='save_address_submit'
							className='capitalize border border-solid border-ufdPurple bg-ufdPurple text-white w-full py-2.5 text-center text-xs font-semibold rounded hover:bg-ufdPurple/80 transition-all disabled:cursor-not-allowed disabled:opacity-50'
							onClick={handleSubmit}
							disabled={type == 'edit' ? false : handleDisableSubmit()}>
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FormAddress;
