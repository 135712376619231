import React from "react";
import ufood from "../../../common/images/ufood-logo.svg";
import driver from "../../../common/images/driver-ilustrator.svg";
import { listStatusDriverExist } from "../../../utils/constants";
import { useHistory, useLocation } from "react-router-dom";
import { formatPhoneNumber, formatPhoneNumberWa } from "../../../utils/helper";

function Tracking({ orderDetails }) {
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;

  const status = orderDetails?.delivery_order_details
    ? orderDetails.delivery_order_details?.status
    : null;
  const foundDriver = listStatusDriverExist.includes(status);
  const driverPhone =
    orderDetails?.delivery_order_details?.track?.courier?.phone;

  const handleCall = (phone = "") => {
    console.log('handleCall');
    const driverPhone = formatPhoneNumber(phone);
    console.log('driverPhone: ', driverPhone);
    if (driverPhone) {
      const formated = `tel:${driverPhone}`;
      console.log('formated: ', formated);
      window.location.href = formated;
    }
  };

  const handleChat = (phone = "") => {
    console.log('handleChat');
    console.log('phone: ', phone);
    const driverPhone = formatPhoneNumberWa(phone);
    console.log('driverPhone: ', driverPhone);
    if (driverPhone) {
      const formated = `https://wa.me/${driverPhone}`;
      console.log('formated: ', formated);
      window.location.href = formated;
    }
  };

  return (
    <div>
      <h2 className="text-sm font-semibold">Track Your Order</h2>
      <div
        className="w-full p-4"
        style={{
          marginTop: "8px",
          marginBottom: "10px",
          border: "1px solid #D3D3D3",
          borderRadius: "8px",
        }}
      >
        <div className="flex items-center justify-between">
          <img src={ufood} alt="Ufood" />
          {orderDetails?.delivery_order_details?.trackingUrl && (
            <a
              id="detail_order_live_tracking"
              className="text-xs font-normal text-blue"
              onClick={() =>
                history.push({
                  pathname: "/tracking",
                  state: {
                    trackingUrl:
                      orderDetails?.delivery_order_details?.trackingUrl || "",
                    backPath: currentPath ?? "/",
                  },
                })
              }
            >
              Live Tracking
            </a>
          )}
        </div>
        <div className="flex mt-4 gap-2">
          <img src={driver} alt="Driver" />
          <div className="flex flex-col">
            <p
              className="font-semibold mr-1 text-black37"
              style={{ color: "#373737" }}
            >
              {orderDetails?.orderStatus === "1"
                ? "Waiting For Merchant Confirmation"
                : !foundDriver
                ? `Searching for Driver`
                : orderDetails?.delivery_order_details?.track?.courier?.name}
            </p>
            <p className="text-xs text-gray-500 pt-1">
              {orderDetails?.orderStatus === "1"
                ? "Please wait"
                : !foundDriver
                ? "Please wait, we’re searching for driver"
                : orderDetails?.store?.[0]?.name}
            </p>
            {foundDriver && (
              <div className="flex justify-start gap-2 pt-1">
                <button
                  id="detail_order_call_driver"
                  className={`${
                    !foundDriver && "hidden"
                  } text-ufdPurple border border-solid border-ufdPurple h-10 w-16 font-medium text-sm`}
                  onClick={() => handleCall(driverPhone)}
                >
                  Call
                </button>
                <button
                  id="detail_order_chat_driver"
                  className={`${
                    !foundDriver && "hidden"
                  } bg-ufdPurple text-white h-10 w-16 font-medium border-solid border-ufdPurple text-sm`}
                  onClick={() => handleChat(driverPhone)}
                >
                  Chat
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tracking;
