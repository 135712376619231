import { useHistory } from 'react-router-dom';
import { NotFoundIcon } from '../../utils/svg';

const NotFound = () => {
	const history = useHistory();
	return (
		<div className={`flex h-screen w-full justify-center items-center bg-white`}>
			<div className='relative h-screen w-full max-w-lg'>
				<div className='flex justify-center items-center h-full flex-col'>
					<NotFoundIcon />
					<div className='mt-5 text-gray-700 font-semibold text-center'>Unable to Process</div>
					<div className='text-center mt-2 text-xs text-gray-700'>
						We are unable to process your request at this time.
						<br />
						Please try again later.
					</div>
					<button
						type='button'
						className={`bg-rose-violet text-white mt-8 w-fit flex justify-between font-normal text-sm py-3 px-16 items-center border-0`}
						onClick={() => history.push('/')}>
						Go Back
					</button>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
