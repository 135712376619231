import useOrder from './useOrder';
import logoImg from '../../../common/images/revamp/images/logo.svg';
import livetrackImg from '../../../common/images/revamp/images/live-track.svg';
import { listStatusDriverExist } from '../../Settings/Config';
import { useHistory, useLocation } from 'react-router-dom';
import { formatPhoneNumber } from '../../../utils/helper';

const Order = () => {
	const { orders } = useOrder();
	const history = useHistory();
	const location = useLocation();
	const currentPath = location.pathname;

	if (!orders?.delivering?.length) return;

	const handleCall = (phone = '') => {
		const driverPhone = formatPhoneNumber(phone);
		if (driverPhone) {
			window.location.href = `tel:${driverPhone}`;
		}
	};

	const handleChat = (phone = '') => {
		const driverPhone = formatPhoneNumber(phone);
		if (driverPhone) {
			window.location.href = `https://wa.me/${driverPhone}`;
		}
	};

	return (
		<div className='w-full mb-[22px] grid gap-2 px-4'>
			<p className='mb-0 text-black37 font-semibold capitalize'>Track Your Order</p>
			{orders?.delivering?.map((item, index) => {
				const status = item?.delivery_order_details ? item?.delivery_order_details?.status : null;
				const foundDriver = listStatusDriverExist.includes(status);
				const driverPhone = item?.delivery_order_details?.track?.courier?.phone;
				return (
					<div key={index} className='rounded-lg border border-solid border-grayD3 shadow-[0px_0px_22px_0px_#0000001A] p-4'>
						<div className='flex items-center justify-between mb-4'>
							<img src={logoImg} alt='logo' className='h-auto w-14 object-cover object-center' />
							<a
								className={`${!foundDriver && 'hidden'} cursor-pointer text-blue hover:text-blue`}
								onClick={() =>
									history.push({
										pathname: '/tracking',
										state: { trackingUrl: item?.delivery_order_details?.trackingUrl || '', backPath: currentPath ?? '/' },
									})
								}>
								Live Tracking
							</a>
						</div>

						<div className='flex gap-2.5 justify-between items-center'>
							<img src={livetrackImg} alt='live-tracking' className='h-auto w-24 object-cover object-center' />
							<div className='grow'>
								<div className='mb-4'>
									<div>
										<span className={`font-semibold mr-1 text-black37`}>
											{item?.orderStatus === '1'
												? 'Waiting For Merchant Confirmation'
												: !foundDriver
												? `Searching for Driver`
												: item?.delivery_order_details?.track?.courier?.name}
										</span>
										<span className={`${!foundDriver ? 'hidden' : ''} text-xs text-gray-500`}>{`(${item?.orderNumber})`}</span>
									</div>
									<span className='text-xs text-gray-500'>
										{item?.orderStatus === '1' ? 'Please wait' : !foundDriver ? 'Please wait, we’re searching for driver' : item?.store?.[0]?.name}
									</span>
								</div>
								{!foundDriver ? (
									<div className='flex justify-start'>
										<button
											className={`bg-ufdPurple text-white h-10 w-32 font-medium border-solid border-ufdPurple text-sm`}
											onClick={() => history.push(`/history/on-process/${item?.orderNumber}`)}>
											Check Order
										</button>
									</div>
								) : (
									<div className='flex flex-col xs:flex-row justify-start gap-2'>
										<div className='flex flex-row gap-2'>
											<button className={`${!foundDriver ? 'hidden' : ''} text-ufdPurple border border-solid border-ufdPurple h-10 w-16 font-medium text-sm`} onClick={() => handleCall(driverPhone)}>
												Call
											</button>
											<button className={`${!foundDriver ? 'hidden' : ''} bg-ufdPurple text-white h-10 w-16 font-medium border-solid border-ufdPurple text-sm`} onClick={() => handleChat(driverPhone)}>
												Chat
											</button>
										</div>
										<button className='bg-ufdPurple text-white h-10 w-28 font-medium border-solid border-ufdPurple text-sm' onClick={() => history.push(`/history/on-process/${item?.orderNumber}`)}>
											Check Order
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default Order;
