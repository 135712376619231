import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  loading: false,
};

const favorite = createSlice({
	name: 'favorite',
	initialState,
	reducers: {
		setFavorite: (state, action) => {
			return { ...state, ...action.payload };
		},
		clearFavorite: () => {
			return initialState;
		},
	}
});

// Export actions and reducer
export const { setFavorite, clearFavorite } = favorite.actions;
export default favorite.reducer;
