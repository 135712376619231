import moment from 'moment-timezone';
moment.locale('id');

export const formatDate = (value, format = 'DD-MM-YYYY H:m', isUTC = false, timeZone = 'Asia/Jakarta') => {
	// Jika tidak ada value, kembalikan string kosong
	if (!value) return '';
	// console.log('value: ', value);

	// Buat objek tanggal menggunakan moment langsung dari value
	let date;

	if (typeof value === 'string') {
		// Jika value sudah dalam format ISO, langsung gunakan moment
		date = moment(value, moment.ISO_8601, true); // True untuk strict parsing
	} else {
		// Jika value bukan string, cek apakah ini valid date object
		date = moment(value);
	}

	// console.log('Moment Date: ', date.isValid() ? date.format() : 'Invalid Date');

	// Periksa apakah date valid
	if (!moment(date).isValid()) {
		// console.log('date: ', date);
		return 'not valid'; // Kembalikan string kosong jika tanggal tidak valid
	}

	// Jika isUTC adalah true, konversi dari UTC ke timezone Jakarta
	if (isUTC) {
		return moment.utc(date).tz(timeZone).format(format);
	} else {
		// Jika tidak, format tanggal langsung dengan timezone yang diberikan
		return moment(date).tz(timeZone).format(format);
	}
};

export const formatPhoneNumber = (phone = '') => {
	if (!phone) return '';
	// If the phone number starts with '+62', return as is
	if (phone.startsWith('+62')) {
		return phone;
	}
	// If the phone number starts with '0', replace the first '0' with '+62'
	if (phone.startsWith('0')) {
		return `+62${phone.slice(1)}`;
	}
	// If the phone number starts with '62', prepend '+'
	if (phone.startsWith('62')) {
		return `+${phone}`;
	}
	// Otherwise, return the phone number as is
	return phone;
};

export const formatRupiah = (value) => {
	return new Intl.NumberFormat("id-ID", {
		style: "currency",
		currency: "IDR",
		minimumFractionDigits: 0,
	}).format(value);
};