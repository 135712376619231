import React, { Component } from 'react';
import cookie from 'react-cookies';
import axios from 'axios';
import waits from '../../common/images/waiting-icon.svg';
import { apiUrl, unquieID } from '../Settings/Config';
import { showAlert } from '../Settings/SettingHelper';
var qs = require('qs');
class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			checkingCount: 0,
		};
	}
	componentDidMount() {
		this.checkingPayment();
	}

	checkingPayment() {

		var postObject = {
			unquieid: unquieID,
			customerID: cookie.load('customerID'),
			paymentReferenceID: cookie.load('paymentOrderID'),
		};

		axios
			.post(apiUrl + 'paymentuvcr/checkPaymentStatus', qs.stringify(postObject))
			.then((res) => {
				console.log("res", res);
				if (res.data.status === 'ok') {
					var currentThis = this;
					if (res.data.paymentStatus === 'SUCCESS') {
						if (res.data.order_local_no !== '') {
							this.props.history.push('/order-completed/' + res.data.order_local_no);
						} else {
							if (this.state.checkingCount <= 100) {
								setTimeout(function () {
									currentThis.setState(
										{
											checkingCount: parseInt(currentThis.state.checkingCount) + 1,
										},
										function () {
											currentThis.checkingPayment();
										},
									);
								}, 3000);
							}
						}

					} else if (res.data.paymentStatus === 'Pending') {
						if (this.state.checkingCount <= 100) {
							setTimeout(function () {
								currentThis.setState(
									{
										checkingCount: parseInt(currentThis.state.checkingCount) + 1,
									},
									function () {
										currentThis.checkingPayment();
									},
								);
							}, 3000);
						}
					} else {
						showAlert('error', res.data.message);
						// this.props.history.push("/");
					}
				} else if (res.data.status === 'error') {
					var message = res.data.form_error !== '' ? res.data.form_error : res.data.message;
					showAlert('error', message);
					// this.props.history.push("/");
				}
			})
			.catch(() => {
				this.checkingPayment();
			});
	}
	placeOrder(paymentStatus) {
		var postObject = window.sessionStorage.getItem('orderData') + '&paymentStatus=' + paymentStatus + '&paymentReferenceID=' + cookie.load('paymentOrderID');
		axios.post(apiUrl + 'orders/placeorder', postObject).then((res) => {
			if (res.data.status === 'ok') {
				var result = res.data.result;
				this.props.history.push('/order-completed/' + result.local_order_no);
			} else if (res.data.status === 'error') {
        showAlert('error', res.data.form_error || res.data.message)
			}
		});
	}

	confirmDelivery(orderObject, local_order_no, orderPrimaryID) {
		var orderObjects = this.queryStringToJSON(orderObject);
		var postObject = {
			unquieid: unquieID,
			customerID: cookie.load('customerID'),
			deliveryOrderID: orderObjects.deliveryOrderID,
			orderPrimaryID: orderPrimaryID,
		};
		axios
			.post(apiUrl + 'deliverypartners/confirmPartnerOrder', qs.stringify(postObject))
			.then((res) => {
				if (res.data.status === 'ok') {
					this.props.history.push('/order-completed/' + local_order_no);
				} else if (res.data.status === 'error') {
					this.props.history.push('/order-completed/' + local_order_no);
				}
			})
			.catch((e) => {});
	}

	queryStringToJSON(queryString) {
		if (queryString.indexOf('?') > -1) {
			queryString = queryString.split('?')[1];
		}
		var pairs = queryString.split('&');
		var result = {};
		pairs.forEach(function (pair) {
			pair = pair.split('=');
			result[pair[0]] = decodeURIComponent(pair[1] || '');
		});
		return result;
	}

	render() {
		return (
			<div className='main-div'>
				<div className='content-body rel'>
					<div className='container' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
						<div className='virtual-acc textcenter wait-clk'>
							<img src={waits} className='clock-vector' style={{ marginRight: 'unset'}} />
							<h1>Please wait a moment</h1>
							<p>
								Our system is currently checking your
								<br /> payment
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Home;
