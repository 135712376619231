import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  productDetail: {},
  loading: false,
};

const product = createSlice({
	name: 'product',
	initialState,
	reducers: {
		setProduct: (state, action) => {
			return { ...state, ...action.payload };
		},
    setProductDetail: (state, action) => {
      console.log('action.payload: ', action.payload);
      // const { brandID, outlets } = action.payload;
      
      // return {
      //   ...state,
      //   outletBrand: {
      //     ...state.outletBrand,
      //     [brandID]: outlets,
      //   },
      // };
    },
		clearProduct: () => {
			return initialState;
		},
	},
});

// Export actions and reducer
export const { setProduct, clearProduct, setProductDetail } = product.actions;
export default product.reducer;
