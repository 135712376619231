import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { encodeValue, formatRupiah, formatDate, axiosInstance } from '../../../utils/helper';
import { Rating } from 'react-simple-star-rating';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { ReactComponent as StarFull } from '../../../common/images/star.svg';
import ufood from '../../../common/images/ufood-logo.svg';
import motorBike from '../../../common/images/Motorbike.svg';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import { uniqueID } from '../../../utils/constants';
import { useSelector } from 'react-redux';
import ImageLoader from '../../ImageLoader/index';

function OrderItem({ item, orderType, ratingChanged, orderAgain }) {
	const history = useHistory();
	const location = useLocation();
	const currentPath = location.pathname;

	const [open, setOpen] = useState(false);

	const [ratingValue, setRatingValue] = useState(0);
	const [readonly, setReadonly] = useState(item?.rating !== '0.00');
	const customerID = useSelector((state) => state.user?.customerID || '');

	const handleRating = (rate) => {
		var postObject = {
			unquieid: uniqueID,
			customerID: encodeValue(customerID),
			orderID: encodeValue(item?.order_primary_id),
			rating: rate,
		};

		axiosInstance
			.post('orders/createRating', qs.stringify(postObject))
			.then((res) => {
				console.log('res', res);
				if (res.data.status === 'ok') {
					setRatingValue(rate);
					setReadonly(true);
				}
			})
			.catch((error) => {
				console.log('error', error);
			});
	};

	const handleSubmit = () => {
		if (ratingValue > 0) {
			ratingChanged(item.order_primary_id, ratingValue);
		}
		setOpen(false);
	};

	let orderClass = '';
	if (['1', '2', '3'].includes(item.orderStatus)) {
		orderClass = 'on-process-list';
	} else if (item.orderStatus === '4') {
		orderClass = 'cmpleted-list';
	} else if (item.orderStatus === '5') {
		orderClass = 'canceled-list';
	} else {
		orderClass = 'waiting-payment-list';
	}

	return (
		<div className={orderClass}>
			<ul>
				<li>
					<div className='flex p-4 w-full border-solid border-b-8 border-gray-100 flex-col'>
						<div className='flex'>
							<div className='rounded-xl w-28 h-fit aspect-square'>
								<ImageLoader src={item.store[0].image} alt={item.store[0].name} />
							</div>
							<div className='pl-3 w-full'>
								<Link to={`/history/${orderType}/${item.orderNumber}`} id='order_history_view_detail'>
									<p className='text-xs text-gray-800 font-normal'>Order ID {item?.orderNumber}</p>
									<h2 className='text-base font-semibold'>
										{item.store.map((storeItem, storeIndex) => (
											<span key={storeIndex}>
												{storeIndex !== 0 ? ', ' : ''}
												{storeItem.name}
											</span>
										))}
									</h2>
									<div className='flex gap-1 items-center my-1'>
										<p className='text-xs text-gray-500 font-normal'>{formatDate(item.order_date, 'DD MMM y, HH:mm')}</p>
										<div className='menu-small'>
											<strong className='!rounded-3xl'>{item.status_name}</strong>
										</div>
									</div>
									<p className='text-xs text-gray-500 font-normal'>{item.orderItemsSummary}</p>
									<p className='text-xs text-gray-500 font-normal'>{item?.totalItem || 0} Items</p>
								</Link>
								<div className='price-and-action border-gray-400 mt-4 pt-4'>
									<div>
										<h3 className='text-sm font-semibold'>{formatRupiah(item.totalAmount)}</h3>
										<p className='text-xs text-gray-500 font-normal'>{item.totalItem} item</p>
									</div>
									{(item.orderStatus === '1' || item.orderStatus === '2') && item.delivery_order_details !== '' && item.delivery_order_details?.trackingUrl !== null && (
										<a
											onClick={() =>
												history.push({
													pathname: '/tracking',
													state: {
														trackingUrl: item.delivery_order_details?.trackingUrl || '',
														backPath: currentPath ?? '/',
													},
												})
											}
											className='button'>
											Track Order
										</a>
									)}
									{(item.orderStatus === '4' || item.orderStatus === '5') && (
										<button onClick={() => orderAgain(item)} className='button hidden' id='order_history_reorder'>
											Order Again
										</button>
									)}
									{item.orderStatus === '6' && (
										<Link to={`/waiting-payment`} className='button'>
											Pay Now
										</Link>
									)}
								</div>
							</div>
						</div>
						{item.orderStatus === '4' && (
							<div
								className='w-full mt-4 cursor-pointer'
								style={{ border: '1px solid #D3D3D3', borderRadius: '6px' }}
								onClick={() => {
									if (item?.rating) return;
									setOpen(true);
								}}>
								<div className='flex justify-between items-center m-3'>
									<p className='text-sm text-gray-800 font-semibold'>Rate your food</p>
									<Rating
										initialValue={parseFloat(item?.rating || 0)}
										onClick={handleRating}
										readonly={readonly}
										allowFraction
										emptyIcon={<StarFull style={{ display: 'inline' }} />}
										fillIcon={<StarFull style={{ display: 'inline' }} />}
									/>
								</div>
							</div>
						)}
					</div>
				</li>
			</ul>
			<BottomSheet onDismiss={() => setOpen(false)} open={open} snapPoints={({ minHeight }) => minHeight}>
				<div className='w-full p-4 flex justify-between items-center'>
					<img src={ufood} alt='Ufood' />
					<p className='text-sm font-semibold' style={{ color: '#373737' }}>
						Delivery successfully
					</p>
				</div>
				<div className='w-full px-4 flex justify-between items-center bg-gray-100 h-[38px]'>
					<p>{formatDate(item.order_date, 'dd MMM y, HH:mm')}</p>
					<p>Order ID UF-1234567890</p>
				</div>
				<div className='w-full p-4 flex'>
					<div className='product-img rounded-xl'>
						{item.store[0].image !== '' && (
							<img
								src={item.store[0].image}
								alt={item.store[0].name}
								style={{
									height: '100%',
									width: '100%',
									objectFit: 'cover',
									objectPosition: 'center',
								}}
							/>
						)}
					</div>
					<div className='ml-3'>
						<h2 className='text-base font-semibold'>
							{item.store.map((storeItem, storeIndex) => (
								<span key={storeIndex}>
									{storeIndex !== 0 ? ', ' : ''}
									{storeItem.name}
								</span>
							))}
						</h2>
						<p className='text-xs font-normal'>Food and Beverage</p>
						<div className='flex gap-1 items-center mt-1'>
							<img src={motorBike} alt='Motorbike' />
							<p className='text-xs font-semibold'>0.8 km</p>
						</div>
					</div>
				</div>
				<div className='w-full px-4'>
					<div
						className='w-full flex flex-col items-center justify-center py-3'
						style={{
							border: '1px solid #D3D3D3',
							borderRadius: '6px',
							gap: '12px',
							marginBottom: '20px',
						}}>
						<h3 className='text-sm font-semibold'>Let's give a rating!</h3>
						<Rating onClick={handleRating} transition allowFraction emptyIcon={<StarFull width={36} height={36} style={{ display: 'inline' }} />} fillIcon={<StarFull width={36} height={36} style={{ display: 'inline' }} />} />
					</div>
					<button
						className='w-full button text-sm font-semibold'
						onClick={handleSubmit}
						style={{
							marginBottom: '26px',
							marginTop: '24px',
							backgroundColor: ratingValue === 0 ? '#ccc' : '',
							color: ratingValue === 0 ? '#666' : '#fff',
							cursor: ratingValue === 0 ? 'not-allowed' : 'pointer',
							border: ratingValue === 0 ? '1px solid #ccc' : '',
						}}
						disabled={ratingValue === 0}>
						Submit
					</button>
				</div>
			</BottomSheet>
		</div>
	);
}

export default OrderItem;
