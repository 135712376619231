import React from 'react';
import { Link } from 'react-router-dom';
import discount from '../../../common/images/discount-shape.svg';
import { showPrice } from "../../Settings/SettingHelper";

function FooterAction({ orderDetails }) {

  return (
    <div
      className="w-full p-4 fixed bottom-0 bg-white"
      style={{ boxShadow: '0px 0px 20px 0px #00000026' }}
    >
      {(orderDetails.orderStatus === '1' ||
        orderDetails.orderStatus === '2' ||
        orderDetails.orderStatus === '3') && (
        <div className="price-and-action">
          <Link
            to="/"
            className="w-full button text-sm font-semibold"
            style={{ paddingTop: '12px', paddingBottom: '12px' }}
          >
            Back to UFood’s Homepage
          </Link>
        </div>
      )}
      {(orderDetails.orderStatus === '4' ||
        orderDetails.orderStatus === '5') && (
        <button
          className="w-full button text-sm font-semibold"
          style={{ marginTop: '0px' }}
          onClick={() => {}}
        >
          Reorder
        </button>
      )}
      {orderDetails.orderStatus === '6' && (
        <>
          <div
            className="flex gap-1 justify-center"
            style={{ marginBottom: '8px' }}
          >
            <img src={discount} alt="discount" />
            <p className="text-xs font-normal">
              Yay, you’re saving 10k. Add more and save up to 50k!
            </p>
          </div>
          <Link
            to="/payment"
            className="w-full flex justify-between button py-3 text-sm font-semibold"
            style={{ marginTop: '0px' }}
          >
            <span>Pay Now</span>
            <span className="text-sm">
              Rp{showPrice(orderDetails.totalAmount)}
            </span>
          </Link>
        </>
      )}
    </div>
  );
}

export default FooterAction;
