import chevron from "../../../common/images/revamp/icon/chevron-white.svg";
import arrow from "../../../common/images/revamp/icon/arrow-white.svg";
import { BottomSheet } from "react-spring-bottom-sheet";
import useDeliveryLocation from "./useDeliveryLocation";
import DeliveryLocationDetail from "./LocationDetail";
import { useSelector } from "react-redux";

const DeliveryLocation = () => {
  /*======================== Props ======================== */

  const { openDetail, handleOpenDetail } = useDeliveryLocation();
  const { savedIsOnSearch } = useSelector((state) => state.location);
  const address = useSelector((store) => store?.user?.address || "");

  /*======================== Return ======================== */

  return (
    <div className="relative">
      <div
        className="flex items-center max-h-10 gap-1.5 grow"
        onClick={(event) => {
          event.preventDefault();
          if (!openDetail) handleOpenDetail();
        }}
      >
        <img src={arrow} alt="arrow-left" className="w-8 h-8 cursor-pointer" />
        <div className="grow">
          <p className="mb-0">Deliver To</p>
          <div className="flex items-center">
            <p className="m-0 mr-2 truncate font-semibold max-w-[calc(100vw-240px)]">
              {address}
            </p>
            <img src={chevron} alt="chevron" className="w-7 h-7" />
          </div>
        </div>
      </div>
      <BottomSheet
        open={openDetail}
        onDismiss={handleOpenDetail}
        snapPoints={({ maxHeight }) => [
          // snap top (90% height)
          maxHeight - maxHeight / 10,
          // snap bottom
          // maxHeight / 4,
          // snap middle
          // maxHeight * 0.6,
        ]}
        header={
          <p className="m-0 py-2 text-left text-lg text-black37 font-semibold">
            {savedIsOnSearch ? "Change Your Saved Location" : "Select Location"}
          </p>
        }
        className="relative pointer-events-auto z-[1000]"
      >
        <DeliveryLocationDetail handleCloseDelivery={handleOpenDetail} />
      </BottomSheet>
    </div>
  );
};

export default DeliveryLocation;
