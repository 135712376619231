import { BottomSheet } from 'react-spring-bottom-sheet';
import DeliveryLocationDetail from './LocationDetail';
import { useSelector } from 'react-redux';
import { ArrowLeftIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const DeliveryLocation = () => {
	const history = useHistory();
	const [openDetail, setOpenDetail] = useState(false);

	useEffect(() => {
		const unblock = history.block((location, action) => {
			if (action === 'POP') {
				setOpenDetail(false);
				return false;
			}
			return true;
		});
		return () => {
			unblock();
		};
	}, [openDetail, history]);

	const handleOpenDetail = () => {
		setOpenDetail((prev) => !prev);
	};

	const { savedIsOnSearch } = useSelector((state) => state.location);
	const address = useSelector((store) => store?.user?.address || '');

	return (
		<div className='relative'>
			<div className='flex items-center max-h-10 gap-1.5 grow'>
				<div
					id='homepage_delivery_to'
					className='grow'
					onClick={(event) => {
						event.preventDefault();
						if (!openDetail) handleOpenDetail();
					}}>
					<p className='mb-0'>Deliver To</p>
					<div className='flex items-center'>
						<p className='m-0 mr-2 truncate font-semibold max-w-[calc(100vw-240px)]'>{address}</p>
						<ChevronDownIcon className='w-4 h-4' strokeWidth={2} />
					</div>
				</div>
			</div>
			<BottomSheet
				open={openDetail}
				onDismiss={handleOpenDetail}
				snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10]}
				header={<p className='m-0 py-2 text-left text-lg text-black37 font-semibold'>Select Location</p>}
				className='relative pointer-events-auto z-[1000]'>
				<DeliveryLocationDetail handleCloseDelivery={handleOpenDetail} />
			</BottomSheet>
		</div>
	);
};

export default DeliveryLocation;
