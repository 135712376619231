import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";
import axios from "axios";
import cookie from "react-cookies";
import {
  stripslashes,
  showPrice,
  encodeValue,
  showLoader,
  hideLoader,
} from "../Settings/SettingHelper";
import { apiUrl, deliveryId, unquieID } from "../Settings/Config";
import Cart from "./Cart";
import barrow from "../../common/images/back-arrow.svg";
import food from "../../common/images/food.png";
import plusi from "../../common/images/plus.svg";
import minusi from "../../common/images/minus.svg";
import favclip from "../../common/images/no-favourite-clip.svg";
import $ from "jquery";
import { isEmptyData } from "../Helpers/SettingHelper";

var qs = require('qs');
class CartList extends Component {
	constructor(props) {
		super(props);

    this.state = {
      cartdetails: "",
      displayCart: [],
      trigerCart: false,
      loader: true,
      cartItemID: "",
      openFilterSheet: true,
      editItem: false,
      removeIDs: [],
      storeOpen: '0'
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    $("body").addClass("hide-overlay");
    $("body").removeClass("bodybgdark");
  }
  componentWillReceiveProps(PropsDt) {
    console.log('componentWillReceiveProps');
    if (this.state.cartdetails !== PropsDt.cartdetails) {
      this.setState(
        { cartdetails: PropsDt.cartdetails, loader: false },
        async function () {
          const storeOpen = await this.loadStoreDetail(PropsDt?.cartdetails?.store?.[0]?.storeID ?? '');
          this.loadCartItem(storeOpen);
        }
      );
    }
  }
  async loadStoreDetail(storeID) {
    console.log('loadStoreDetail');
    if (isEmptyData(storeID)) return '0';
    return await axios.get(`${apiUrl}store/storeDetails?unquieid=${unquieID}&availabilityID=${deliveryId}&storeID=${storeID}`).then((res) => {
      return res?.data?.result?.storeOpen ?? '0';
		});
  }
  sateValChange = (field, value) => {
    console.log('sateValChange');
    this.setState({ [field]: value });
  };
  loadCartItem(storeOpen = '0') {
    console.log('loadCartItem');
    var displayCart = [];
    if (Object.keys(this.state.cartdetails).length > 0) {
      if (this.state.cartdetails.item.length > 0) {
        displayCart = this.state.cartdetails.item.map((item, index) => {
          return (
            <li key={index}>
              <div className="cart-outlet-title">
                <h3>{this.state.cartdetails.store.storeName}</h3>
              </div>
                <div
                  className="cart-product-cover"
                  id={"cart_" + item.itemID}
                  key={index}
                >
                  <div className="cart-product-flex">
                    {this.state.editItem === true && (
                      <div className="edit_custom_radio">
                        <input
                          type="checkbox"
                          onChange={this.handleChange}
                          defaultValue={item.itemID}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        height: '80px',
                        width: '80px',
                        backgroundColor: item.itemImage
                          ? ''
                          : '#bababa4a',
                      }}>
                      <img
                        style={{
                          height: '100%',
                          width: '100%',
                          objectFit: 'cover',
                          objectPosition: 'center',
                        }}
                        src={item.itemImage || food}
                        alt='Eats Image'
                      />
                    </div>
                    <div className="product-desc">
                      <h3 className="ot-title">
                        {stripslashes(item.itemName)}
                      </h3>
                      {item.comboset.length > 0 && (
                        <div className="otp-desc-comob">
                          {item.comboset.map((comboItem, comobIndex) => {
                            return (
                              <p key={comobIndex}>
                                <strong>{comboItem.comboSetname}</strong>
                                <br />
                                {comboItem.productDetails.length > 0 &&
                                  comboItem.productDetails.map(
                                    (comobPro, comboProIndex) => {
                                      return (
                                        <span key={comboProIndex}>
                                          {comboProIndex !== 0 && " + "}
                                          {stripslashes(
                                            comobPro.productName
                                          )}
                                          {parseInt(comobPro.quantity) >
                                            0 && (
                                            <>
                                              <b> x{comobPro.quantity} </b>
                                            </>
                                          )}
                                          {parseFloat(
                                            comobPro.productPrice
                                          ) > 0 && (
                                            <>
                                              (+
                                              {showPrice(
                                                comobPro.productPrice
                                              )}
                                              )
                                            </>
                                          )}
                                        </span>
                                      );
                                    }
                                  )}
                              </p>
                            );
                          })}
                        </div>
                      )}
                      <div className="pro-action">
                        <div className="pro-price">
                          {showPrice(item.itemTotalPrice)}
                        </div>
                        <div className="pro-qtybx">
                          <div className="qty-bx">
                            <span
                              className="qty-minus"
                              onClick={this.proQtyIncDecUpdate.bind(
                                this,
                                item.itemID,
                                item.itemQuantity,
                                this.state.cartdetails.store.storeID,
                                "decr"
                              )}
                            >
                              <img src={minusi} alt="Minus" />
                            </span>
                            <div className="input-quantity">
                              {item.itemQuantity}
                            </div>
                            <span
                              className="qty-plus"
                              onClick={this.proQtyIncDecUpdate.bind(
                                this,
                                item.itemID,
                                item.itemQuantity,
                                this.state.cartdetails.store.storeID,
                                "inc"
                              )}
                            >
                              <img src={plusi} alt="Plus" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </li>
          );
        });
      }
    }
    hideLoader("cart_" + this.state.cartItemID);
    this.setState({ displayCart: displayCart, cartItemID: "", storeOpen: storeOpen }, function () {
      $('.edit_custom_radio input[type="checkbox"]').prop("checked", false);
    });
  }
  proQtyIncDecUpdate(itemID, itemQuantity, storeID, type) {
    console.log('proQtyIncDecUpdate');
    var updQty = 0;
    if (type === "inc") {
      updQty = parseInt(itemQuantity) + 1;
    } else {
      updQty = parseInt(itemQuantity) - 1;
    }
    this.setState({ cartItemID: itemID }, function () {
      showLoader("cart_" + itemID);
    });
    var postObject = {
      unquieid: unquieID,
      availabilityID: deliveryId,
      shopID: encodeValue(storeID),
      customerID: cookie.load("customerID"),
      itemID: itemID,
      quantity: updQty,
    };

		axios.post(apiUrl + 'cart/updateCartItem', qs.stringify(postObject)).then((res) => {
			// hideLoader("proDtIndex-" + IndexFlg, "Idtext");

			if (res.data.status === 'ok') {
				this.setState({ trigerCart: true, removeIDs: [], editItem: false });
				/*  removePromoCkValue();
            this.handleShowAlertFun(
              "Success",
              "Great choice! Item added to your cart."
            ); */
			} else if (res.data.status === 'error') {
				/*   var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
            this.handleShowAlertFun("Error", errMsgtxt); */
			}

			return false;
		});
	}
	editItems() {
		console.log('editItems');
		this.setState({ editItem: !this.state.editItem }, function () {
			this.setState({ removeIDs: [] });
			this.loadCartItem();
		});
	}
	handleChange(event) {
		console.log('handleChange');
		var updateID = this.state.removeIDs;
		var value = event.target.value;
		if (event.target.checked) {
			updateID.push(value);
		} else {
			var idIndex = updateID.indexOf(value);
			updateID.splice(idIndex, 1);
		}
		this.setState({ removeIDs: updateID });
		console.log(event.target.checked, updateID);
	}
	removeItem() {
		console.log('removeItem');
		if (this.state.removeIDs.length > 0) {
			var postObject = {
				unquieid: unquieID,
				customerID: cookie.load('customerID'),
				itemID: this.state.removeIDs.join(','),
			};
			showLoader('removecartItem', 'class');
			axios.post(apiUrl + 'cart/deleteCartItem', qs.stringify(postObject)).then((res) => {
				if (res.data.status === 'ok') {
					this.setState({ trigerCart: true, removeIDs: [] });
				} else if (res.data.status === 'error') {
				}
				hideLoader('removecartItem', 'class');
			});
		}
	}
	render() {
		return (
			<div className='main-div'>
				<Cart sateValChange={this.sateValChange} trigerCart={this.state.trigerCart} />
				<div className='header-action header-action-center'>
					<div className='container'>
						<div className='hac-lhs'>
							<Link to={'/'} className='arrow-back'>
								<img src={barrow} />
							</Link>
						</div>
						<div className='ha-middle'>Cart</div>
						<div className='ha-rhs ha-rhs-link'>
							<a href={void 0} onClick={this.editItems.bind(this)}>
								{this.state.editItem === true ? 'Cancel' : 'Edit'}
							</a>
						</div>
					</div>
				</div>
				<div className='content-body rel'>
					<div className='container'>
						<div className='cart-list'>
							<ul>
								{this.state.loader === true ? (
									Array(1, 2, 3).map((item) => {
										return (
											<li key={item}>
												<ContentLoader viewBox='0 0 380 70'>
													<rect x='0' y='0' rx='5' ry='5' width='70' height='70' />
													<rect x='80' y='17' rx='4' ry='4' width='300' height='13' />
													<rect x='80' y='40' rx='3' ry='3' width='250' height='10' />
												</ContentLoader>
											</li>
										);
									})
								) : !this.state.displayCart?.length ? (
									<div className='no-favtxt'>
										<img src={favclip} />
										<h2>There is no cart item</h2>
										<p>click the add to cart on your favorite restaurant</p>
										<Link to={'/restaurants'} className='button'>
											Find a restaurant
										</Link>
									</div>
								) : (
									this.state.displayCart
								)}
							</ul>
						</div>
					</div>
				</div>

				{this.state.loader === false && this.state.displayCart !== '' && this.state.openFilterSheet === true && (
					<footer className='footer-main'>
						<IonFooter collapse='fade'>
							{this.state.removeIDs.length > 0 && (
								<div className='remove-selected-item'>
									<span>{this.state.removeIDs.length} Item Selected.</span>
								</div>
							)}
							<div className='order-full'>
								{this.state.removeIDs.length > 0 ? (
									<a href={void 0} onClick={this.removeItem.bind(this)} className='button of-btn removecartItem'>
										Delete
									</a>
								) : (
									<Link to={'/summary'} className='button of-btn'>
										Order
									</Link>
								)}
							</div>
						</IonFooter>
					</footer>
				)}
			</div>
		);
	}
}

const mapStateTopProps = (state) => {
	var cartdetailsArr = Array();
	if (Object.keys(state.cartdetails).length > 0) {
		if (state.cartdetails[0].status === 'ok') {
			cartdetailsArr = state.cartdetails[0].result;
		}
	}
	return {
		cartdetails: cartdetailsArr,
	};
};

export default connect(mapStateTopProps)(withRouter(CartList));
