import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiUrl, unquieID } from '../../Settings/Config';
import { encodeValue } from '../../Helpers/SettingHelper';
import axios from 'axios';
import { setOrder } from '../../../store/reducers/order';

const useOrder = () => {
	const dispatch = useDispatch();

	const customerID = useSelector((store) => store?.user?.customerID);
	const orders = useSelector((store) => store?.order);
	const [loading, setLoading] = useState(true);

	const handleGetOrders = async () => {
		console.log('handleGetOrders');
		try {
			setLoading(true);
			const url = `${apiUrl}orders/orderList?customerID=${encodeValue(customerID)}&unquieid=${unquieID}&orderType=Ongoing`;
			const res = await axios.get(url);
			if (res?.data?.status === 'ok') {
				const result = res?.data?.result;
				dispatch(
					setOrder({
						delivering: result,
					}),
				);
			}
			setLoading(false);
		} catch (error) {
			console.log('error: ', error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (customerID) {
			handleGetOrders();
		}
	}, [customerID]);

	useEffect(() => {
		if (!customerID && !orders?.delivering?.length) return;
		let interval;
		if (orders?.delivering?.length) {
			interval = setInterval(() => {
				handleGetOrders();
			}, 10000);
		}

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [orders, customerID]);

	// useEffect(() => {
	//   if (customerID && !orders) {
	//     dispatch({
	//       type: GET_ORDER_LIST,
	//       params: {
	//         customerID,
	//         unquieid: unquieID,
	//         orderType: "Ongoing",
	//       },
	//     });
	//   }
	// }, []);

	return {
		orders,
		loading,
	};
};

export default useOrder;
