import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axiosInstance";

const initialState = {
  data: [],
  loading: false,
};

export const getBannerList = createAsyncThunk(
  "banner/getBannerList",
  async (params) => {
    const res = await axiosInstance.get("banner/listBanner", { params });
    const data = await res.data;
    return data;
  }
);

const banner = createSlice({
  name: "banner",
  initialState,
  reducers: {
    setBanner: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearBanner: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBannerList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBannerList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.result;
      });
  },
});

// Export actions and reducer
export const { setBanner, clearBanner } = banner.actions;
export default banner.reducer;
