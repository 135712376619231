import { useState, useEffect } from "react";

const useSearchHeader = () => {
  /*======================== UseState ======================== */

  const [shadowHeader, setShadowHeader] = useState(false);

  /*======================== Handler ======================== */

  const toggleVisibilityShadow = () => {
    setShadowHeader(window.scrollY > 0);
  };

  /*======================== UseEffect ======================== */

  // Adding event listener for scrolling header shadow
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibilityShadow);

    return () => {
      window.removeEventListener("scroll", toggleVisibilityShadow);
    };
  }, []);

  /*======================== Return ======================== */

  return {
    shadowHeader,
  };
};

export default useSearchHeader;
