import { Route, Switch } from "react-router-dom";
import useRouter from "./useRouter";
import Layout from "../components/Layout";
import NotFound from "../pages/NotFound"
import ConnectionChecker from '../components/Errors/ConnectionChecker';

const RouteConfig = () => {
  const { routes } = useRouter();

  return (
    <ConnectionChecker>
      <Layout>
        <Switch>
          {routes.map(({ exact, index, path, component }) => (
            <Route key={path} {...{ index, exact, path, component }} />
          ))}
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </ConnectionChecker>
  );
};

export default RouteConfig;
