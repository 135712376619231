import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import food from "../common/images/food.png";

// interface IProps {
//   image: string;
//   altImage?: string;
//   classImage?: string;
//   classContainer?: string;
// }
const ImageLoader = (props) => {
  const { classContainer, srcImage, altImage, classImage, onClick } = props;
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const imgRef = useRef(null);

  useEffect(() => {
    if (imgRef.current?.complete) {
      handleImageLoad();
    }
  }, []);

  return (
    <div className={twMerge("relative", classContainer)}>
      {!isImageLoaded && (
        <div
          className={twMerge(
            "absolute h-full w-full rounded-sm bg-grayD3",
            classImage
          )}
        ></div>
      )}
      <img
        ref={imgRef}
        src={srcImage || food}
        alt={altImage}
        className={classImage}
        onLoad={() => {
          handleImageLoad();
        }}
        onClick={onClick}
      />
    </div>
  );
};

export default ImageLoader;
