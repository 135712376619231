import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axiosInstance";

const initialState = {
  data: [],
  loading: false,
};

export const getCategoryList = createAsyncThunk(
  "category/getCategoryList",
  async () => {
    const res = await axiosInstance.get("food-type/list");
    const data = await res.data;
    return data;
  }
);

const category = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategory: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearCategory: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCategoryList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.result;
      });
  },
});

// Export actions and reducer
export const { setCategory, clearCategory } = category.actions;
export default category.reducer;
