export const centerLocation = {
	lat: -1.2378167595291314,
	lng: 114.02678743956167,
};

const ultraVoucher = {
	code: 'ULTRA-VOUCHER',
	name: 'Ultra Value',
	minimum_amount: 1,
	maximum_amount: 10000000,
	status: 'ACTIVE',
	fee: 0,
	image_url: 'https://uvdev.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2019-12-23T03%3A16%3A35.175Z1577070994-group.jpeg',
	image_size: '',
	image_size_payment: 'w-10 h-auto',
};

const vaBni = {
	code: 'VABNI',
	name: 'Virtual Account BNI',
	minimum_amount: 10000,
	maximum_amount: 100000000,
	status: 'ACTIVE',
	fee: 2000,
	image_url: 'https://uvproduction.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2022-09-02T02%3A40%3A55.520Z1662086455-BNI.png',
	image_size: 'w-9 h-auto',
	image_size_payment: 'w-14 h-auto',
};

const vaBca = {
	code: 'VADIRECTBCA',
	name: 'Virtual Account BCA',
	minimum_amount: 15000,
	maximum_amount: 9000000,
	status: 'ACTIVE',
	fee: 2000,
	image_url: 'https://uvdev.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2019-09-30T03%3A39%3A05.606Z1569814745-group.jpeg',
	image_size: 'w-9 h-auto',
	image_size_payment: 'w-16 h-auto',
};
const vaMandiri = {
	code: 'VAMANDIRI',
	name: 'Virtual Account Mandiri',
	minimum_amount: 10000,
	maximum_amount: 100000000,
	status: 'ACTIVE',
	fee: 1500,
	image_url: 'https://uvproduction.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2023-07-11T11%3A21%3A41.987Z1689074501-group.png',
	image_size: 'w-12 h-auto',
	image_size_payment: 'w-20 h-auto',
};
const vaBri = {
	code: 'VABRI',
	name: 'Virtual Account BRI',
	minimum_amount: 10000,
	maximum_amount: 100000000,
	status: 'ACTIVE',
	fee: 2000,
	image_url: 'https://uvproduction.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2022-09-02T02%3A39%3A37.034Z1662086376-BRI.png',
	image_size: 'w-7 h-auto',
	image_size_payment: 'w-11 h-auto',
};
const vaPermata = {
	code: 'VAPERMATA',
	name: 'Virtual Account PERMATA',
	minimum_amount: 10000,
	maximum_amount: 100000000,
	status: 'ACTIVE',
	fee: 2000,
	image_url: 'https://uvproduction.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2022-09-02T02%3A36%3A49.642Z1662086209-Permata.png',
	image_size: 'w-20 h-auto',
	image_size_payment: 'w-28 h-auto',
};

export const listPaymentMethod = [
	{
		id: 2,
		category: 'Payment Method',
		image_url: '',
		payment_method: [ultraVoucher],
	},
	{
		id: 4,
		category: 'Other Payment Method',
		image_url: '',
		payment_method: [vaBni, vaBca, vaMandiri, vaBri, vaPermata],
	},
];

export const OrderStatusList = {
	1: { message: 'Waiting For Merchant Confirmation', className: 'sr-process' },
	2: { message: 'On Progress', className: 'sr-process' },
	3: { message: 'On Progress', className: 'sr-process' },
	4: { message: 'Completed', className: 'sr-completed' },
	5: { message: 'Canceled', className: 'sr-cancel' },
	6: { message: 'Waiting Payment', className: 'sr-waiting-payment' },
};

export const ListPaymentMethodAll = [ultraVoucher, vaBni, vaBca, vaMandiri, vaBri, vaPermata];
