import { useEffect, useState } from 'react';

const useLayout = () => {
	/*======================== UseState ======================== */

	const [isVisibleScrollToTop, setIsVisibleScrollToTop] = useState(false);
	const [isNearBottom, setIsNearBottom] = useState(false);

	/*======================== Handler ======================== */

	// Function to detect scroll position
	const toggleVisibility = () => {
		const scrollTop = window.scrollY;
		const windowHeight = window.innerHeight;
		const documentHeight = document.documentElement.scrollHeight;

		if (scrollTop > 300) {
			setIsVisibleScrollToTop(true);
		} else {
			setIsVisibleScrollToTop(false);
		}

		// Check if scroll is near the bottom (within 100px)
		if (documentHeight - (scrollTop + windowHeight) < 300) {
			setIsNearBottom(true);
		} else {
			setIsNearBottom(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	/*======================== UseEffect ======================== */

	// Adding event listener for scroll
	useEffect(() => {
		window.addEventListener('scroll', toggleVisibility);

		return () => {
			window.removeEventListener('scroll', toggleVisibility);
		};
	}, []);

	/*======================== Return ======================== */

	return {
		isVisibleScrollToTop,
    isNearBottom,
		scrollToTop,
	};
};

export default useLayout;
