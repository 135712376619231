import React from "react";
import { twMerge } from "tailwind-merge";

const UFInput = (props) => {
  /*======================== Props ======================== */

  const {
    label,
    required,
    optional,
    type = "text",
    placeholder,
    value,
    disabled,
    errorMessage,
    onChange,
    maxLength,
  } = props;

  /*======================== Return ======================== */

  return (
    <div>
      <div
        className={twMerge(
          "flex",
          required && "items-start",
          optional && "items-center"
        )}
      >
        <label className="text-black37 font-semibold text-xxs">{label}</label>
        {required && <span className="text-xxs text-[#F84B4B]">*</span>}
        {optional && <span className="text-xxs text-gray90">(optional)</span>}
      </div>
      <div className="flex items-center gap-1.5">
        {type === "tel" && (
          <span className="text-black37 font-semibold">+62</span>
        )}
        <input
          {...{ placeholder, type, value, disabled, maxLength, onChange }}
          className="!px-0 py-1.5 focus:outline-none !bg-white focus:!bg-gray-100 w-full disabled:text-gray-400 disabled:opacity-50 !border-b !border-solid !border-grayD !mb-0.5 !rounded-none"
        />
      </div>
      {required && (
        <p className="text-[#F84B4B] text-xxs font-medium h-[15px]">
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default UFInput;
