import React from 'react';
import OrderItem from './OrderItem';

function OrderList({ orderList, orderType, ratingChanged, orderAgain }) {
  return (
    <>
      {orderList.map((item, index) => (
        <OrderItem
          key={index}
          item={item}
          orderType={orderType}
          ratingChanged={ratingChanged}
          orderAgain={orderAgain}
        />
      ))}
    </>
  );
}

export default OrderList;
