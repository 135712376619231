import React from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import NoOutletImg from "../../../../common/images/revamp/images/no-outlet.png";

const NoOutlet = ({ isOpen, onClose }) => {
  /*======================== Return ======================== */

  return (
    <BottomSheet open={isOpen} onDismiss={onClose}>
      <div className="px-4 pb-4">
        <img
          src={NoOutletImg}
          alt="no-outlet"
          className="h-[182px] mx-auto mb-4"
        />
        <p className="text-black37 font-semibold text-sm px-6 mb-1.5 text-center">
          Sorry, There are no restaurant available around your location
        </p>
        <p className="text-black37 text-xxs px-1 mb-8 text-center">
          Please change your location to find a good deals from a restaurant
        </p>

        <button
          className="border border-solid border-ufdPurple text-ufdPurple w-full py-2.5 text-center text-xs font-semibold rounded hover:bg-ufdPurple hover:text-white transition-all"
          onClick={onClose}
        >
          Change Location
        </button>
      </div>
    </BottomSheet>
  );
};

export default NoOutlet;
