import React from 'react';
import ufood from '../../../common/images/ufood-logo.svg';
import driver from '../../../common/images/driver-ilustrator.svg';
import { listStatusDriverExist } from '../../Settings/Config';
import { useHistory, useLocation } from 'react-router-dom';
import { formatPhoneNumber } from '../../../utils/helper';
import '../../../tailwind.css';

function Tracking({ orderDetails }) {
	const history = useHistory();
	const location = useLocation();
	const currentPath = location.pathname;

	const status = orderDetails?.delivery_order_details ? orderDetails.delivery_order_details?.status : null;
	const foundDriver = listStatusDriverExist.includes(status);
	const driverPhone = orderDetails?.delivery_order_details?.track?.courier?.phone;

	const handleCall = (phone = '') => {
		const driverPhone = formatPhoneNumber(phone);
		if (driverPhone) {
			window.location.href = `tel:${driverPhone}`;
		}
	};

	const handleChat = (phone = '') => {
		const driverPhone = formatPhoneNumber(phone);
		if (driverPhone) {
			window.location.href = `https://wa.me/${driverPhone}`;
		}
	};

	return (
		<div>
			<h2 className='text-sm font-semibold'>Track Your Order</h2>
			<div
				className='w-full p-4'
				style={{
					marginTop: '8px',
					marginBottom: '10px',
					border: '1px solid #D3D3D3',
					borderRadius: '8px',
				}}>
				<div className='flex items-center justify-between'>
					<img src={ufood} alt='Ufood' />
					{orderDetails?.delivery_order_details?.trackingUrl && (
						<a
							className='text-xs font-normal text-blue'
							onClick={() =>
								history.push({
									pathname: '/tracking',
									state: { trackingUrl: orderDetails?.delivery_order_details?.trackingUrl || '', backPath: currentPath ?? '/' },
								})
							}>
							Live Tracking
						</a>
					)}
				</div>
				<div className='flex mt-4 gap-2'>
					<img src={driver} alt='Driver' />
					<div className='flex flex-col'>
						<p className='font-semibold mr-1 text-black37' style={{ color: '#373737' }}>
							{!foundDriver ? `Searching for Driver` : orderDetails?.delivery_order_details?.track?.courier?.name}
						</p>
						<p className='text-xs text-gray-500 pt-1'>{!foundDriver ? 'Please wait,  we’re searching for driver' : orderDetails?.store?.[0]?.name}</p>
						{foundDriver && (
							<div className='flex justify-start gap-2 pt-1'>
								<button
									className={`${!foundDriver && 'hidden'} text-ufdPurple border border-solid border-ufdPurple h-10 w-16 font-medium text-sm`}
									onClick={() => handleCall(driverPhone)}>
									Call
								</button>
								<button
									className={`${!foundDriver && 'hidden'} bg-ufdPurple text-white h-10 w-16 font-medium border-solid border-ufdPurple text-sm`}
									onClick={() => handleChat(driverPhone)}>
									Chat
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Tracking;
