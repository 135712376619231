import { ChevronUpIcon } from "@heroicons/react/24/solid";
import useLayout from "./useLayout";

const Layout = ({ children }) => {
  /*======================== Props ======================== */

  const { isVisibleScrollToTop, scrollToTop } = useLayout();

  /*======================== Return ======================== */

  return (
    <div>
      <div className="relative w-full h-full">
        <div className="bg-white laptop:bg-whitef8">{children}</div>
      </div>
      {/* Scroll Top */}
      {isVisibleScrollToTop && (
        <div
          className="block go-top fixed bottom-4 right-4 bg-ufdPurple rounded-5 p-2 cursor-pointer z-[5] text-white rounded-md"
          onClick={scrollToTop}
        >
          <ChevronUpIcon className="w-4 h-4" />
        </div>
      )}
    </div>
  );
};

export default Layout;
