import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiUrl, deliveryId, unquieID } from '../../components/Settings/Config';
import { encodeValue, formatRupiah, isEmptyData } from '../../components/Helpers/SettingHelper';
import Loader from '../../components/Loader/index';
import '../../tailwind.css';
import { useHistory } from 'react-router';
import { MagnifyingGlassIcon, ArrowLeftIcon, StarIcon } from '@heroicons/react/24/solid';
import { HeartIcon } from '@heroicons/react/24/outline';
import { BikeIcon, DiscountIcon, TagIcon, WishtlingIcon } from '../../utils/svg';
import Footer from '../../components/Footer';
import qs from 'qs';
import ProductDetail from '../../components/Product';
import ProductSimple from '../../components/Product/simple';
import food from '../../common/images/food.png';
import { BottomSheet } from 'react-spring-bottom-sheet';
import ProductCombo from '../../components/Product/combo';
import { useDispatch, useSelector } from 'react-redux';
import useToast from '../../components/Toast';
import FoodImg from '../../common/images/food.png';

const Outlet = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { slug } = useParams();

	const { showToast } = useToast();

	const user = useSelector((store) => store?.user);

	const [isLoadingCart, setisLoadingCart] = useState(true);
	const [outlet, setOutlet] = useState(null);
	const [products, setProducts] = useState([]);
	const [tags, setTags] = useState([]);
	const [cart, setCart] = useState(null);

	const [isShowTotalCart, setIsShowTotalCart] = useState(false);
	const [isShowSimpleProduct, setIsShowSimpleProduct] = useState(false);
	const [isShowComboProduct, setIsShowComboProduct] = useState(false);

	const [productSlug, setProductSlug] = useState(null);
	const [cartItem, setCartItem] = useState(null);

	const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false);

	const [isChangeStoreCart, setIsChangeStoreCart] = useState(false);
	const [selectedTag, setSelectedTag] = useState('all');

	const handleGetStore = useCallback(async () => {
		if (!user?.customerID) return;
		console.log('handleGetStore');
		try {
			setisLoadingCart(true);
			let url = `${apiUrl}store/storeDetails?unquieid=${unquieID}&customerID=${encodeValue(user?.customerID)}`;
			if (/^\d+$/.test(slug)) {
				url += `&storeID=${slug}`;
			} else {
				url += `&storeSlug=${slug}`;
			}

			if (user?.latitude && user?.longitude) {
				url += `&latitude=${user?.latitude}&longitude=${user?.longitude}`;
			}
			const res = await axios.get(url, {
				headers: {
					Accept: 'application/json, text/plain, */*',
				},
			});
			if (res.data?.status === 'ok') {
				const data = res.data?.result;
				setOutlet(data);
			}
			setisLoadingCart(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingCart(false);
		}
	}, [slug, user?.customerID, unquieID]);

	const handleGetProducts = useCallback(async () => {
		if (!outlet?.storeID) return;
		console.log('handleGetProducts');
		try {
			setisLoadingCart(true);
			const store_id = encodeValue(outlet?.storeID);
			let url = `${apiUrl}catalogs/listproducts?unquieid=${unquieID}&storeID=${store_id}`;

			const res = await axios.get(url, {
				headers: {
					Accept: 'application/json, text/plain, */*',
				},
			});
			if (res.data?.status === 'ok') {
				setProducts(res.data?.result || []);
			}
			setisLoadingCart(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingCart(false);
		}
	}, [outlet, unquieID]);

	const handleCart = async (data, isClose = true) => {
		console.log('handleCart', data);
		try {
			setisLoadingCart(true);
			let postData = {
				unquieid: unquieID,
				availabilityID: deliveryId,
				availabilityName: 'Delivery',
				shopID: encodeValue(outlet?.storeID),
				customerID: encodeValue(user?.customerID),
			};
			if (data?.productType === 'Simple') {
				postData = {
					...postData,
					...data,
				};
			} else {
				postData = {
					...postData,
					...data,
					productTotalPrice: data?.totalPrice,
					productPrice: data?.price,
					comboset: JSON.stringify(data?.comboset),
				};
			}
			const url = `${apiUrl}cart/${data?.type === 'add' ? 'createCart' : 'updateCartItem'}`;
			const res = await axios.post(url, qs.stringify(postData));
			if (res?.data?.status === 'ok') {
				isClose && setCartItem(null);
				isClose && handleCloseBottomSheet();
				await handleGetCart();
			}
			setisLoadingCart(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingCart(false);
		}
	};

	const handleRemoveCart = async () => {
		console.log('handleRemoveCart');
		try {
			setisLoadingCart(true);
			const postData = {
				unquieid: unquieID,
				customerID: encodeValue(user?.customerID),
			};
			const res = await axios.post(`${apiUrl}cart/removeCart`, qs.stringify(postData));
			if (res?.data?.status === 'ok') {
				setCart(null);
			}
			if (productSlug) {
				console.log('handleGetProductDetail', productSlug);
				await handleGetProductDetail(productSlug, true);
			}
			setisLoadingCart(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingCart(false);
		}
	};

	const handleGetCart = useCallback(async () => {
		if (!user?.customerID) return;
		console.log('handleGetCart');
		try {
			setisLoadingCart(true);
			const url = `${apiUrl}cart/loadCartDetails?unquieid=${unquieID}&customerID=${encodeValue(user?.customerID)}&availabilityID=${deliveryId}`;
			const res = await axios.get(url, {
				headers: {
					Accept: 'application/json, text/plain, */*',
				},
			});
			if (res?.data?.status === 'ok') {
				setCart(res?.data?.result || null);
			}
			if (res?.data?.message === 'There are no items in your cart') {
				setCart(null);
			}
			setisLoadingCart(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingCart(false);
		}
	}, [user?.customerID]);

	const handleGetTags = useCallback(async () => {
		if (!outlet?.storeID) return;
		console.log('handleGetTags');
		try {
			setisLoadingCart(true);
			const url = `${apiUrl}catalogs/listcategory?unquieid=${unquieID}&storeID=${encodeValue(outlet?.storeID)}`;
			const res = await axios.get(url);
			if (res?.data?.status === 'ok') {
				const allCategory = {
					categoryName: 'All',
					categoryImage: '',
					catSlug: 'all',
				};
				const updatedTags = [allCategory, ...(res?.data?.result || [])];
				setTags(updatedTags);
			}
			setisLoadingCart(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingCart(false);
		}
	}, [outlet, unquieID]);

	useEffect(() => {
		handleGetStore();
		handleGetCart();
	}, [user?.customerID]);

	useEffect(() => {
		handleGetProducts();
		handleGetTags();
	}, [outlet?.storeID]);

	useEffect(() => {
		if (!cart?.item?.length || isOpenBottomSheet) return;
		setIsShowTotalCart(true);
	}, [cart?.item?.length, isOpenBottomSheet]);

	const handleGetProductDetail = async (slug, removeCart = false) => {
		if (!slug) return;
		console.log('handleGetProductDetail');
		try {
			if (!removeCart && cart?.store?.storeID && cart?.store?.storeID !== outlet?.storeID) {
				setProductSlug(slug);
				return setIsChangeStoreCart(true);
			}
			setisLoadingCart(true);
			const url = `${apiUrl}catalogs/productdetails?unquieid=${unquieID}&productSlug=${slug}`;
			const res = await axios.get(url, {
				headers: {
					Accept: 'application/json, text/plain, */*',
				},
			});
			if (res.data?.status === 'ok') {
				const product = res.data?.result || null;
				setCartItem(product);
				if (product?.product_type === '1') {
					setIsShowSimpleProduct(true);
				}
				if (product?.product_type === '2') {
					setIsShowComboProduct(true);
				}
				setIsOpenBottomSheet(true);
				setIsShowTotalCart(false);
			}
			if (res?.data?.status === 'error') {
				showToast('warning', 'Product not found', 1000);
			}
			setProductSlug(null);
			setisLoadingCart(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingCart(false);
		}
	};

	const handleFavorite = useCallback(async () => {
		if (!user?.customerID) return;
		console.log('handleFavorite');
		try {
			setisLoadingCart(true);
			const dataPost = {
				unquieid: unquieID,
				shopID: encodeValue(outlet?.storeID),
				customerID: encodeValue(user?.customerID),
			};
			const url = `${apiUrl}store/${Boolean(Number(outlet?.favorite || outlet?.favourite)) ? 'removeFavourite' : 'addFavourite'}`;
			const res = await axios.post(url, qs.stringify(dataPost));
			if (res?.data?.status === 'ok') {
				handleGetStore();
			}
			setisLoadingCart(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingCart(false);
		}
	}, [outlet, user]);

	const handleCloseBottomSheet = () => {
		console.log('handleCloseBottomSheet');
		setIsOpenBottomSheet(false);
		setIsShowTotalCart(true);
		setIsShowSimpleProduct(false);
		setIsShowComboProduct(false);
		setIsChangeStoreCart(false);
	};

	const handleRemoveCartAndGetProduct = () => {
		console.log('handleRemoveCartAndGetProduct');
		handleCloseBottomSheet();
		handleRemoveCart();
	};

	const filteredProduct = useMemo(() => {
		if (selectedTag === 'all') return products;
		const filter = products?.filter((product) => selectedTag === product?.pro_cate_slug);
		return filter;
	}, [selectedTag, products]);

	return (
		<>
			{isLoadingCart && <Loader overlay />}
			<div className='h-screen w-full flex justify-center items-center'>
				<div className='relative h-screen w-full max-w-lg'>
					<div className='aspect-[2/1] w-full h-full max-h-52 absolute'>
						<img alt={outlet?.storeSlug} src={outlet?.bannerImage || food} className='h-full w-full object-cover' />
					</div>
					<div className={`absolute mt-5 w-full max-w-lg`}>
						<div className='flex flex-row justify-between mx-4'>
							<div className='flex justify-center items-center cursor-pointer' onClick={() => history.push('/')}>
								<ArrowLeftIcon className='h-8 w-8 text-gray-900 bg-white rounded-full p-1.5' />
							</div>
							<div className='flex gap-2'>
								<div className='hidden justify-center items-center cursor-pointer' onClick={() => history.push('/')}>
									<MagnifyingGlassIcon className='h-8 w-8 text-gray-900 bg-white rounded-full p-1.5' />
								</div>
								<div className='flex justify-center items-center cursor-pointer' onClick={handleFavorite}>
									<HeartIcon
										className={`h-8 w-8 ${Boolean(Number(outlet?.favorite || outlet?.favourite)) ? 'fill-red-500 text-red-500' : 'text-gray-900'} bg-white rounded-full p-1.5`}
									/>
								</div>
							</div>
						</div>
						<div className='flex mx-4 bg-white mt-16 rounded-lg p-4 shadow-xl'>
							<img alt={outlet?.storeSlug} src={outlet?.storeImage || FoodImg} className='h-12 w-12 rounded-lg resize-none' />
							<div className='pl-3'>
								<div className='text-gray-900 text-sm font-semibold'>{outlet?.storeName}</div>
								<div className='flex gap-1 items-center pt-1'>
									<StarIcon className='h-4 w-4 text-star' />
									<div className='text-black text-xs'>{outlet?.rating || outlet?.Rating || 0}</div>
									<div className='text-gray-900 text-xs'>({outlet?.totalRating || 0})</div>
								</div>
								<div className='flex flex-row gap-1 pt-1'>
									<BikeIcon />
									<div className='flex flex-row'>
										<div className='text-gray-900 text-xs'>{Math.floor(Number(outlet?.distance || 0) * 10) / 10} km</div>
										<div className='hidden text-xs'>
											<div className='px-1'>-</div>
											<div className='flex flex-row'>
												Delivery <p className='px-1 line-through'>Rp16.000</p> Rp6.000
											</div>
										</div>
									</div>
								</div>
								<div className={`${!outlet?.offerInfo && 'hidden'} gap-1 pt-1 flex items-center`}>
									<TagIcon />
									<div className='font-semibold text-xs text-gray-900'>{outlet?.offerInfo}</div>
								</div>
							</div>
						</div>
						<div className='mx-4 mt-5 hidden'>
							<div className='text-base text-gray-800 font-semibold'>Favorite’s Items You Maybe Like</div>
							<div className='mt-4 flex overflow-scroll scrollbar-hide'>
								{filteredProduct?.map((product, index) => {
									return (
										<ProductDetail
											productKey={`${product?.product_id}_${index}`}
											id={product?.product_id}
											slug={product?.product_slug}
											image={product?.product_thumbnail}
											rating={outlet?.rating || outlet?.Rating}
											totalRating={outlet?.totalRating}
											name={product?.product_name}
											price={Number(product?.product_price)}
											strikeOutPrice={Number(product?.product_cost)}
											size='small'
											productType={product?.product_type}
											handleGetProductDetail={handleGetProductDetail}
											cart={cart}
											handleCart={handleCart}
										/>
									);
								})}
							</div>
						</div>
						<div className={`mx-4 mt-5 ${cart && 'mb-24'}`}>
							<div className='text-base text-gray-800 font-semibold'>Feature Items</div>
							<div className='w-full my-2'>
								<div className='grid grid-flow-col auto-cols-min overflow-x-auto snap-x snap-mandatory scroll-smooth scroll-px-4 gap-2 hide-scroll'>
									{tags?.map((item, index) => {
										return (
											<div
												key={index}
												className={`snap-start cursor-pointer rounded-full bg-gray-100 px-3 py-1.5 text-gray-500 ${
													selectedTag === item?.catSlug && 'bg-rose-violet bg-opacity-15 text-rose-violet'
												}`}
												onClick={() => setSelectedTag(item?.catSlug)}>
												<span className='text-center text-nowrap text-xs'>{item?.categoryName}</span>
											</div>
										);
									})}
								</div>
							</div>
							<div className='mt-4 grid grid-cols-2 gap-4'>
								{filteredProduct?.map((product, index) => {
									return (
										<ProductDetail
											productKey={`${product?.product_id}_${index}`}
											id={product?.product_id}
											slug={product?.product_slug}
											image={product?.product_thumbnail}
											rating={outlet?.rating || outlet?.Rating}
											totalRating={outlet?.totalRating}
											name={product?.product_name}
											price={Number(product?.product_price)}
											strikeOutPrice={Number(product?.product_cost)}
											productType={product?.product_type}
											handleGetProductDetail={handleGetProductDetail}
											cart={cart}
											handleCart={handleCart}
										/>
									);
								})}
							</div>
						</div>
					</div>
					{isShowTotalCart && (
						<Footer position='fixed' id='cart'>
							<div className={`bg-white p-4 shadow-custom transition-transform duration-1000 ease-in-out ${cart ? 'translate-y-0 relative' : 'translate-y-full hidden'}`}>
								<div className='items-center hidden'>
									<DiscountIcon />
									<div className='pl-2 text-gray-900 text-xs'>Yay, you’re saving 10k. Add more and save up to 50k!</div>
								</div>
								<button
									className='mt-2 w-full bg-rose-violet text-white flex justify-between font-normal text-sm p-3 border-0items-center border-0'
									onClick={() => history.push('/summary')}>
									<div>{cart?.totalItem} item in cart</div>
									<div>{formatRupiah(parseInt(cart?.subTotal || 0))}</div>
								</button>
							</div>
						</Footer>
					)}
					{isChangeStoreCart && (
						<Footer position='fixed' id='isChangeStoreCart' onTouchOutside={() => setIsChangeStoreCart(false)}>
							<div
								className={`bg-white p-4 rounded-xl shadow-custom transition-transform duration-1000 ease-in-out ${
									isChangeStoreCart ? 'translate-y-0 relative' : 'translate-y-full hidden'
								}`}>
								<div className='flex justify-center'>
									<div className='bg-gray-300 h-1.5 w-10 rounded-full' />
								</div>
								<div className='items-center flex justify-center mt-8'>
									<WishtlingIcon />
								</div>
								<div className='my-5 text-center'>
									<div className='text-gray-900 text-sm font-semibold'>Want to buy from this restaurant?</div>
									<div className='text-gray-900 text-xs pt-2'>We'll need to clear the items in your current cart.</div>
								</div>
								<div className='flex justify-between mt-2 gap-4'>
									<button className='w-full bg-white text-rose-violet border-rose-violet font-semibold text-sm p-3 items-center border' onClick={() => setIsChangeStoreCart(false)}>
										Cancel
									</button>
									<button onClick={handleRemoveCartAndGetProduct} className='w-full bg-rose-violet text-white font-semibold text-sm p-3 items-center border-0'>
										Yes, Sure
									</button>
								</div>
							</div>
						</Footer>
					)}
					{isOpenBottomSheet && (
						<BottomSheet
							open={isOpenBottomSheet}
							onDismiss={handleCloseBottomSheet}
							snapPoints={({ minHeight, maxHeight }) => {
								// 544 932
								if (isShowComboProduct && cartItem?.comboset?.length && !cart) {
									return [755];
								}
								return [minHeight];
							}}>
							{isShowSimpleProduct && <ProductSimple product={cartItem} cart={cart} handleCart={handleCart} setCartItem={setCartItem} />}
							{isShowComboProduct && <ProductCombo product={cartItem} cart={cart} handleCart={handleCart} setCartItem={setCartItem} />}
						</BottomSheet>
					)}
				</div>
			</div>
		</>
	);
};

export default Outlet;
