import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../utils/helper';

export const getOrderList = createAsyncThunk(
  "order/getOrderList",
  async (params) => {
    const res = await axiosInstance.get(`orders/orderList`, { params });
    const data = await res.data;
    return data;
  }
);

export const getOrderDetail = createAsyncThunk(
  "order/getOrderDetail",
  async (params) => {
    const res = await axiosInstance.get(`orders/orderDetails`, { params });
    const data = await res.data;
    return data;
  }
);

const OrderStatus = {
  RECEIVED: 1,
  DELIVERING: 2,
  PROCESSING: 3,
  COMPLETED: 4,
  CANCELED: 5,
}

const initialState = {
  all: [],
  received: [],
  delivering: [],
  processing: [],
  completed: [],
  canceled: [],
  detail: null,
  loading: false,
  error: null,
  params: {
    customerID: null,
    unquieid: null,
    orderNumber: null,
  },
};

const groupByOrderStatus = (data = []) => {
  return data.reduce((acc, current) => {
    const status = current.orderStatus;
    if (!acc[status]) {
      acc[status] = [];
    }
    acc[status].push(current);
    return acc;
  }, {});
};

const order = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrder: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearOrder: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrderList.fulfilled, (state, action) => {
        state.loading = false;
        const data = action.payload.result || [];
        const group = groupByOrderStatus(data);
        
        state.all = data;
        state.received = group[OrderStatus.RECEIVED] || [];
        state.delivering = group[OrderStatus.DELIVERING] || [];
        state.processing = group[OrderStatus.PROCESSING] || [];
        state.completed = group[OrderStatus.COMPLETED] || [];
        state.canceled = group[OrderStatus.CANCELED] || [];
      })
      .addCase(getOrderDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrderDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.detail = action.payload.result || null;
      })
  }
});

export const { setOrder, clearOrder } = order.actions;
export default order.reducer;