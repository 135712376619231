import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	session_token: null,
  data: null,
  date: null,
  timeLeft: null,
  status: null,
};

const payment = createSlice({
	name: 'payment',
	initialState,
	reducers: {
		setPayment: (state, action) => {
			return { ...state, ...action.payload };
		},
		clearPayment: () => {
			return initialState;
		},
	},
});

// Export actions and reducer
export const { setPayment, clearPayment } = payment.actions;
export default payment.reducer;
