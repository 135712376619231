import { listStatusDriverExist } from '../../utils/constants';
import { useHistory, useLocation } from 'react-router-dom';
import { formatPhoneNumber, formatPhoneNumberWa } from '../../utils/helper';
import { LiveTrackingIcon, UFoodIcon } from '../../utils/svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniqueID } from '../../utils/constants';
import { axiosInstance, encodeValue } from '../../utils/helper';
import { setOrder } from '../../store/reducers/order';

const Order = () => {
	const dispatch = useDispatch();

	const customerID = useSelector((store) => store?.user?.customerID);
	const orders = useSelector((store) => store?.order);
	const [loading, setLoading] = useState(true);

	const handleGetOrders = async () => {
		console.log('handleGetOrders');
		try {
			setLoading(true);
			const url = `orders/orderList?customerID=${encodeValue(customerID)}&unquieid=${uniqueID}&orderType=Ongoing`;
			const res = await axiosInstance.get(url);
			if (res?.data?.status === 'ok') {
				const result = res?.data?.result;
				dispatch(
					setOrder({
						delivering: result,
					}),
				);
			}
			setLoading(false);
		} catch (error) {
			console.log('error: ', error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (customerID) {
			handleGetOrders();
		}
	}, [customerID]);

	useEffect(() => {
		if (!customerID && !orders?.delivering?.length) return;
		let interval;
		if (orders?.delivering?.length) {
			interval = setInterval(() => {
				handleGetOrders();
			}, 10000);
		}

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [orders, customerID]);

	const history = useHistory();
	const location = useLocation();
	const currentPath = location.pathname;

	if (!orders?.delivering?.length) return;

	const handleCall = (phone = '') => {
		console.log('handleCall');
		const driverPhone = formatPhoneNumber(phone);
		console.log('driverPhone: ', driverPhone);
		if (driverPhone) {
			const formated = `tel:${driverPhone}`
			console.log('formated: ', formated);
			window.location.href = formated;
		}
	};

	const handleChat = (phone = '') => {
		console.log('handleChat');
		console.log('phone: ', phone);
		const driverPhone = formatPhoneNumberWa(phone);
		console.log('driverPhone: ', driverPhone);
		if (driverPhone) {
			const formated = `https://wa.me/${driverPhone}`;
			window.location.href = formated;
		}
	};

	return (
		<div className='w-full mb-[22px] grid gap-2 px-4 max-w-lg'>
			<p className='mb-0 text-black37 font-semibold capitalize'>Track Your Order</p>
			{orders?.delivering?.map((item, index) => {
				const status = item?.delivery_order_details ? item?.delivery_order_details?.status : null;
				const foundDriver = listStatusDriverExist.includes(status);
				const driverPhone = item?.delivery_order_details?.track?.courier?.phone;
				return (
					<div key={index} className='rounded-lg border border-solid border-grayD3 shadow-[0px_0px_22px_0px_#0000001A] p-4'>
						<div className='flex flex-row items-center justify-between mb-4 '>
							<UFoodIcon className='h-auto w-14 object-cover object-center' />
							<a
								id='homepage_track_order_live_tracking'
								className={`${!foundDriver && 'hidden'} cursor-pointer text-blue hover:text-blue`}
								onClick={() =>
									history.push({
										pathname: '/tracking',
										state: { trackingUrl: item?.delivery_order_details?.trackingUrl || '', backPath: currentPath ?? '/' },
									})
								}>
								Live Tracking
							</a>
						</div>
						<div className='flex gap-2.5 justify-between items-center'>
							<LiveTrackingIcon className='h-auto min-w-24 w-24 object-cover object-center' />
							<div className='grow'>
								<div className='mb-4'>
									<div>
										<span className={`font-semibold mr-1 text-black37`}>
											{item?.orderStatus === '1'
												? 'Waiting For Merchant Confirmation'
												: !foundDriver || item?.order_driver_name == null || item?.order_driver_phone == null
													? `Searching for Driver`
													: item?.delivery_order_details?.track?.courier?.name}
										</span>
										<span className={`${!foundDriver ? 'hidden' : ''} text-xs text-gray-500`}>{`(${item?.orderNumber})`}</span>
									</div>
									<span className='text-xs text-gray-500'>{item?.orderStatus === '1' ? 'Please wait' : !foundDriver ? 'Please wait, we’re searching for driver' : item?.store?.[0]?.name}</span>
								</div>
								{!foundDriver ? (
									<div className='flex justify-start'>
										<button
											className={`bg-ufdPurple text-white h-10 w-32 font-medium border-solid border-ufdPurple text-sm`}
											onClick={() => history.push(`/history/on-process/${item?.orderNumber}`)}
											id='homepage_track_order_check'>
											Check Order
										</button>
									</div>
								) : (
									<div className='flex flex-col xs:flex-row justify-start gap-2'>
										<div className='flex flex-row gap-2'>
											<button
												className={`${!foundDriver ? 'hidden' : ''} text-ufdPurple border border-solid border-ufdPurple h-10 w-16 font-medium text-sm`}
												onClick={() => handleCall(driverPhone)}
												id='homepage_track_order_call_driver'>
												Call
											</button>
											<button
												className={`${!foundDriver ? 'hidden' : ''} bg-ufdPurple text-white h-10 w-16 font-medium border-solid border-ufdPurple text-sm`}
												onClick={() => handleChat(driverPhone)}
												id='homepage_track_order_chat_driver'>
												Chat
											</button>
										</div>
										<button className='bg-ufdPurple text-white h-10 w-28 font-medium border-solid border-ufdPurple text-sm' onClick={() => history.push(`/history/on-process/${item?.orderNumber}`)}>
											Check Order
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default Order;
