import { ShopOpenIcon } from '../../utils/svg';

export const TooFar = ({ show = false, handleClickButton = () => {} }) => {
	return (
		<div className={`bg-white p-4 shadow-custom transition-transform duration-1000 ease-in-out rounded-t-xl ${show ? 'translate-y-0 relative' : 'translate-y-full hidden'}`}>
			<div className='flex justify-center'>
				<ShopOpenIcon />
			</div>
			<div className='flex flex-col w-full items-center text-center'>
				<div className='flex justify-center pt-4 text-base text-gray-900 font-semibold max-w-96'>Sorry, There are no restaurant available around your location</div>
				<div className='flex justify-center pt-2 text-[0.72rem] text-gray-700 font-normal'>Please change your location to find a good deals from a restaurant</div>
			</div>
			<button
				className='mt-8 w-full bg-white text-rose-violet flex justify-center font-semibold text-sm p-3 border-1 items-center border-solid border-rose-violet'
				onClick={handleClickButton}>
				Change Location
			</button>
		</div>
	);
};
