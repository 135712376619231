import { twMerge } from "tailwind-merge";
import useCategory from "./useCategory";
import check from "../../../common/images/revamp/icon/check.svg";
import OwlCarousel from "react-owl-carousel2";
import "../../../common/css/owl.carousel.css";
import "./category.css";
import food from "../../../common/images/food.png";

const Category = () => {
  /*======================== Props ======================== */

  const { categories, selected, handleSelect } = useCategory();

  const bannerOptions = {
    items: 5,
    dots: true,
    margin: 15,
    stagePadding: 30,
    stageOuterClass: "!pl-4 !overflow-visible owl-stage-outer",
  };

  /*======================== Return ======================== */

  return (
    <div className="w-full category mb-4.5 max-w-lg">
      <OwlCarousel options={bannerOptions}>
        {categories.map((item, index) => (
          <div
            key={index}
            className="w-full cursor-pointer"
            onClick={() => handleSelect(item.foodTypeID)}
          >
            <div
              className={twMerge(
                "mx-auto rounded-[14px] mb-1.5 relative transition-all",
                selected.includes(item.foodTypeID) &&
                  "outline outline-2 outline-ufdPurple"
              )}
            >
              <img
                src={item.foodTypeImage || food}
                alt="category-img"
                className="mx-auto w-16 h-16 object-cover object-center rounded-xl"
              />
              <div
                className={twMerge(
                  "w-4.5 h-4.5 text-white border-2 border-solid border-white rounded-full bg-ufdPurple grid place-content-center absolute top-[-9px] left-1 transition-all opacity-0",
                  selected.includes(item.foodTypeID) && "opacity-100"
                )}
              >
                <img src={check} alt="check" />
              </div>
            </div>

            <p className="text-center line-clamp-1 text-xs text-gray-600">
              {item.foodTypeName}
            </p>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default Category;
