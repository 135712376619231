import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_OUTLET_BY_LOCATION,
  SET_OUTLET_BY_LOCATION,
} from "../../../../actions/outletAction";
import { useHistory } from 'react-router-dom';

const useOutletByLocation = ({ outlet }) => {
  /*======================== Props ======================== */
  const history = useHistory();
  /*======================== Store ======================== */

  const dispatch = useDispatch();
  const { outletByLocation: outletList } = useSelector((state) => state.outlet);

  /*======================== Handler ======================== */

  const handleAction = (item) => {
    if (item && item.storeOpen == "1") {
      history.push("/outlet/" + item.storeSlug);
    }
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    if (outlet) {
      dispatch({
        type: GET_OUTLET_BY_LOCATION,
        payload: {
          outlet,
        },
      });
    }
    return () => {
      dispatch({
        type: SET_OUTLET_BY_LOCATION,
        payload: undefined,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlet]);

  /*======================== Return ======================== */

  return {
    outletList,
    handleAction,
  };
};

export default useOutletByLocation;
