import React, { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import FoodImg from '../../common/images/food.png';
import { timeout } from '../../utils/constants';

const preloadImageWithErrorHandling = (src, fallbackSrc) => {
  const img = new Image();
  img.src = src;

  return new Promise((resolve) => {
      const timer = setTimeout(() => {
          resolve(fallbackSrc);
      }, timeout);

      img.onload = () => {
          clearTimeout(timer);
          resolve(src);
      };
      
      img.onerror = () => {
          clearTimeout(timer);
          resolve(fallbackSrc);
      };
  });
};


const ImageLoader = ({ src = '', alt = '', className = 'h-full w-full rounded-xl object-cover object-center' }) => {
	const [imageSrc, setImageSrc] = useState(null);

	useEffect(() => {
		let isCancelled = false;

		const loadImage = async () => {
			try {
				const loadedImageSrc = await preloadImageWithErrorHandling(src, FoodImg);
				if (!isCancelled) {
					setImageSrc(loadedImageSrc);
				}
			} catch (error) {
				console.error('Error loading image: ', error);
				if (!isCancelled) {
					setImageSrc(FoodImg);
				}
			}
		};

		loadImage();
		return () => {
			isCancelled = true;
		};
	}, [src, timeout]);

	return (
		<>
			{!imageSrc ? (
				<ContentLoader speed={2} width='100%' height='100%' backgroundColor='#f3f3f3' foregroundColor='#ecebeb' className={className}>
					<rect x='0' y='0' rx='10' ry='10' width='100%' height='100%' />
				</ContentLoader>
			) : (
				<img alt={alt} src={imageSrc} className={className} />
			)}
		</>
	);
};

export default ImageLoader;
