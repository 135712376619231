import { useEffect, useState } from "react";

const useVoucherDetail = () => {
  /*======================== UseState ======================== */

  const [expand, setExpand] = useState("");

  /*======================== Handler ======================== */

  const handleExpand = (value) => {
    setExpand(value === expand ? "" : value);
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    document.body.className = "overflow-hidden pointer-events-none";
    return () => {
      document.body.className = "";
    };
  }, []);

  /*======================== Return ======================== */

  return {
    expand,
    handleExpand,
  };
};

export default useVoucherDetail;
