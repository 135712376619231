import React from 'react';
import { stripslashes } from '../../Settings/SettingHelper';

function OrderSummary({ orderDetails }) {
  return (
    <div
      className="w-full px-4"
      style={{ border: '1px solid #D3D3D3', marginBottom: '13px' }}
    >
      {orderDetails?.orderItems?.length > 0 &&
        orderDetails?.orderItems?.map((store, storeIndex) => {
          return (
            <div className="product-order" key={storeIndex}>
              {store?.items?.length > 0 && (
                <>
                  <p
                    className="text-xs text-gray-500 font-normal"
                    style={{ marginTop: '17px', marginBottom: '2px' }}
                  >
                    Order Summary
                  </p>
                  <ul style={{ marginBottom: '17px' }}>
                    {store.items.map((item, index) => {
                      return (
                        <li key={index} style={{ paddingTop: '10px' }}>
                          <div className="flex justify-between">
                            <p className="text-xs text-gray-900 font-semibold">
                              {item.itemName}
                            </p>
                            <p className="text-xs text-gray-900 font-semibold">
                              {item.itemQuantity}x
                            </p>
                          </div>
                          <div className="ot-top">
                            <div className="otp-desc">
                              <div className="otp-history-desc">
                                {item.comboset.length > 0 && (
                                  <div className="otp-desc-comob">
                                    {item.comboset.map(
                                      (comboItem, comboIndex) => {
                                        return (
                                          <p key={comboIndex}>
                                            <strong>
                                              {comboItem.comboSetname}
                                            </strong>
                                            <br />
                                            {comboItem.productDetails.length >
                                              0 &&
                                              comboItem.productDetails.map(
                                                (comboPro, comboProIndex) => {
                                                  return (
                                                    <span key={comboProIndex}>
                                                      {comboProIndex !== 0 &&
                                                        ' + '}
                                                      {stripslashes(
                                                        comboPro.productName
                                                      )}
                                                      {parseInt(
                                                        comboPro.quantity
                                                      ) > 0 && (
                                                        <>
                                                          <b>
                                                            {' '}
                                                            x
                                                            {comboPro.quantity}
                                                          </b>
                                                        </>
                                                      )}
                                                    </span>
                                                  );
                                                }
                                              )}
                                          </p>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {item.itemNote !== '' && item.itemNote !== null && (
                            <div className="ot-note">
                              <span>Note :</span>
                              <p>{item.itemNote}</p>
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
            </div>
          );
        })}
    </div>
  );
}

export default OrderSummary;
