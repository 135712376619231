import React, { useState, useEffect } from 'react';
import noConnectionPng from '../../common/images/revamp/images/noconnectionpng.png';

const ConnectionChecker = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (isOnline) {
    return children;
  }

  return (
    <div className='flex flex-col justify-center items-center h-screen text-center p-5 bg-white'>
      <div className='mb-5'>
        <img src={noConnectionPng} alt='no-connection' className='h-auto w-30 object-cover object-center' />
      </div>
      <h2 className="text-2xl font-bold mb-2">No Connection</h2>
      <p className="text-base text-gray-600 mb-5">Oops! It seems you're currently offline.</p>
      <button className="px-5 py-2.5 text-base text-white bg-[#A84198] rounded-lg w-[300px]" onClick={() => window.location.reload()}>
        Try Again
      </button>
    </div>
  );
};

export default ConnectionChecker;
