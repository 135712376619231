import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/helper";

const initialState = {
  savedIsOpen: false,
  savedIsOnSearch: false,
  savedInitialForm: null,
  savedSearchResult: null,

  savedData: [],
  savedLoading: false,
  savedFormLoading: false,

  recentData: [],
  recentLoading: false,
};

export const getSavedList = createAsyncThunk(
  "location/getSavedList",
  async (user_id) => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("limit", null);

    const res = await axiosInstance.post("address/list", formData);
    const data = await res.data;
    return data;
  }
);

export const getRecentList = createAsyncThunk(
  "location/getRecentList",
  async (user_id) => {
    const formData = new FormData();
    formData.append("user_id", user_id);

    const res = await axiosInstance.post("address/list-history", formData);
    const data = await res.data;
    return data;
  }
);

const location = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocation: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearSearch: (state) => {
      return {
        ...state,
        savedIsOpen: false,
        savedIsOnSearch: false,
        savedInitialForm: null,
        savedSearchResult: null,
      };
    },
    clearLocation: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSavedList.pending, (state) => {
        state.savedLoading = true;
      })
      .addCase(getSavedList.fulfilled, (state, action) => {
        state.savedLoading = false;
        state.savedData = action.payload.data;
      });
    builder
      .addCase(getRecentList.pending, (state) => {
        state.recentLoading = true;
      })
      .addCase(getRecentList.fulfilled, (state, action) => {
        state.recentLoading = false;
        state.recentData = action.payload.data;
      });
  },
});

// Export actions and reducer
export const { setLocation, clearSaved, clearSearch, clearLocation } =
  location.actions;
export default location.reducer;
