import motorbike from '../../common/images/revamp/icon/motorbike.svg';
import tagPrice from '../../common/images/revamp/icon/tag-purple.svg';
import star from '../../common/images/revamp/icon/star.svg';
import { ChevronRightIcon, ClockIcon } from '@heroicons/react/24/solid';
import OutletByLocation from './Location';
import { MathRandom, sendAnalyticsCustomEvent, sendAnalyticsEvent, checkTime, checkHoldTime } from '../../utils/helper';
import NoOutlet from './NoOutlet';
import { useMemo } from 'react';
import { LoaderIcon } from '../../utils/svg';
import ImageLoader from '../ImageLoader';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniqueID } from '../../utils/constants';
import { setOutlet, setOutletBrand } from '../../store/reducers/outlet';
import useLayout from '../Layout/useLayout';
import { useHistory } from 'react-router-dom';
import { axiosInstance } from '../../utils/helper';

const Outlet = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { isNearBottom } = useLayout();

	const outlets = useSelector((state) => {
		console.log('Redux State:', state.outlet?.list);
		return state.outlet?.list || [];
	});
	const outletBrand = useSelector((state) => state.outlet?.outletBrand || {});

	const loading = useSelector((state) => state.outlet?.loading || false);
	const latitude = useSelector((state) => state.user?.latitude);
	const longitude = useSelector((state) => state.user?.longitude);

	const [isOpenOtherLocation, setIsOpenOtherLocation] = useState(false);
	const [outletByLocation, setOutletByLocation] = useState([]);
	const [brandID, setBrandID] = useState(null);
	const [isLoadingOtherLocation, setisLoadingOtherLocation] = useState(false);
	const [selectedStoreID, setSelectedStoreID] = useState(null);

	const [totalPage, setTotalPage] = useState(0);
	const [total, setTotal] = useState(1);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [isLoadMore, setIsLoadMore] = useState(false);

	useEffect(() => {
		if (!brandID || !outletBrand?.[brandID]?.length || !isOpenOtherLocation) return;
		setOutletByLocation(outletBrand[brandID]);
	}, [brandID, outletBrand, isOpenOtherLocation]);

	useEffect(() => {
		if (isOpenOtherLocation) return;
		setOutletByLocation([]);
		setSelectedStoreID(null);
	}, [isOpenOtherLocation]);

	useEffect(() => {
		const unblock = history.block((location, action) => {
			if (action === 'POP') {
				setIsOpenOtherLocation(false);
				return false;
			}
			return true;
		});
		return () => {
			unblock();
		};
	}, [isOpenOtherLocation, history]);

	const handleOutletByLocation = async (item = null) => {
		console.log('handleOutletByLocation');
		setisLoadingOtherLocation(true);
		setBrandID(item?.brandID);
		setSelectedStoreID(item?.storeID);
		setIsOpenOtherLocation(true);
		const url = `store/listStore?brand_id=${item?.brandID}&latitude=${latitude}&longitude=${longitude}`;
		const res = await axiosInstance.get(url);
		if (res?.data?.status === 'ok') {
			const result = res?.data?.result || [];
			dispatch(setOutletBrand({ brandID: item?.brandID, outlets: result }));
		}
		setisLoadingOtherLocation(false);
	};

	const handleGetOutletList = useCallback(
		async (page = 1, limit = 10, isLoadMore = false) => {
			console.log('handleGetOutletList', isLoadMore);
			try {
				let url = `store/listStore?unquieid=${uniqueID}&latitude=${latitude}&longitude=${longitude}&page=${page}&limit=${limit}`;
				const res = await axiosInstance.get(url);
				if (res?.data?.status === 'ok') {
					const result = res?.data?.result || [];
					const totalPage = res?.data?.total_pages || 0;
					const page = res?.data?.page || 0;
					const total = Number(res?.data?.total) || 0;
					const limit = res?.data?.limit || 0;

					console.log('data from API:', res?.data?.result);
					if (isLoadMore) {
						const newItems = result.filter((item) => !outlets.some((outlet) => outlet.storeID === item.storeID));
						dispatch(
							setOutlet({
								list: [...outlets, ...newItems],
							}),
						);
					} else {
						dispatch(
							setOutlet({
								list: result,
							}),
						);
					}
					setTotalPage(totalPage);
					setPage(page);
					setTotal(total);
					setLimit(limit);
					setIsLoadMore(false);
				}
			} catch (error) {
				console.log('error: ', error);
			}
		},
		[page, limit, totalPage, latitude, longitude, uniqueID, isLoadMore],
	);

	useEffect(() => {
		dispatch(
			setOutlet({
				list: [],
			}),
		);
		handleGetOutletList();
	}, [latitude, longitude]);

	useEffect(() => {
		if (!isNearBottom || isLoadMore || page >= totalPage) return;
		setIsLoadMore(true);
		const nextPage = page + 1;
		handleGetOutletList(nextPage, limit, true);
	}, [isNearBottom]);

	const selectedTag = useSelector((store) => store?.tag?.selected);
  console.log('useSelector selectedTag : ', selectedTag);

	const filteredOutlet = outlets?.filter((outlet) => {
		const tagArray = outlet?.tagID ? outlet.tagID.split(',') : [];
		if (!selectedTag) return true;
		return tagArray.includes(String(selectedTag));
	});
	

	console.log("filteredOutlet", filteredOutlet)

	const handleClickOutlet = (item) => {
		console.log('handleClickOutlet');
		if (item?.storeOpen === '1') {
			history.push({
				pathname: `/outlet/${item?.storeSlug}`,
				state: {
					storeID: item?.storeID,
					storeSlug: item?.storeSlug,
					storeName: item?.storeName,
					storeImage: item?.storeImage,
					rating: item?.rating,
					bannerImage: item?.storeBannerImage
				},
			});
		}
	};

	useEffect(() => {
		sendAnalyticsCustomEvent('view_merchants_list', {
			merchants_list_id: 'related_products',
			merchants_list_name: 'Related products',
			items: outlets,
		});
	}, []);

	return (
		<div className='w-full px-4 mb-10 space-y-[26px] max-w-lg'>
			{loading &&
				new Array(6).fill('').map((item, index) => (
					<div key={index} className='flex justify-between gap-3'>
						<div className='rounded-lg bg-grayD3 animate-pulse w-[95px] h-[95px]'></div>
						<div className='grow space-y-1'>
							<div className='rounded-sm bg-grayD3 animate-pulse h-[21px] max-w-full' style={{ width: `${MathRandom(80, 200)}px` }}></div>
							<div className='rounded-sm bg-grayD3 animate-pulse h-[21px] w-16 max-w-full'></div>
							<div className='rounded-sm bg-grayD3 animate-pulse h-[21px] max-w-full' style={{ width: `${MathRandom(60, 90)}px` }}></div>
							<div className='rounded-sm bg-grayD3 animate-pulse h-[21px] w-36 max-w-full'></div>
						</div>
					</div>
				))}

			{filteredOutlet?.map((item, index) => {
				const distance = parseFloat(item?.distance || 0)?.toFixed('2');
				const isBusy = item?.endHold && checkHoldTime(item?.endHold);
				const outletStatus = isBusy
					? `Resto sedang sibuk`
					: `Closed${item?.outletOpenTime
						? ` - Open at ${item?.outletOpenTime} ${checkTime(item?.outletOpenTime)} Today`
						: ""}`;

				return (
					<div key={item?.id || index} className='flex justify-between gap-3'>
						<div
							className='aspect-[1/1] h-[95px] w-[95px] cursor-pointer'
							onClick={() => {
								sendAnalyticsEvent('Click Outlet', 'homepage_select_outlet', item?.storeName);
								handleClickOutlet(item);
							}}
							id='homepage_select_outlet'>
							<ImageLoader src={item?.storeImage} alt={item?.storeImage} />
						</div>
						<div className='grow'>
							<div onClick={() => handleClickOutlet(item)} className='grow space-y-1'>
								<div className={`${item?.storeOpen === '0' ? 'flex' : 'hidden'} items-center`}>
									<ClockIcon class='h-4 w-4 text-red-500 mr-1' />
									<div className='text-xs font-normal text-gray-900'>
										{outletStatus}
									</div>
								</div>
								<p className={`mb-0 font-semibold cursor-pointer ${item?.storeOpen === '0' ? 'text-gray-400' : 'text-black37'} line-clamp-1 max-w-[calc(100vw-139px)]`}>{item?.storeName}</p>
								{item?.rating && (
									<div className='flex items-center gap-1'>
										<img src={star} alt='star' />
										<span className='font-medium text-sm text-black37'>
											{item?.rating || 0} ({item?.totalRating || 0})
										</span>
									</div>
								)}
								<div className='flex items-center gap-1'>
									<img src={motorbike} alt='motorbike' />
									<span>{distance || 0} km</span>
								</div>
								{item?.storeTimeInfo && (
									<div className='flex items-center gap-1'>
										<img src={tagPrice} alt='tagPrice' />
										<span className='font-medium text-sm text-black37'>{item?.storeTimeInfo}</span>
									</div>
								)}
								{item?.offerInfo && (
									<div className='flex items-center gap-1'>
										<img src={tagPrice} alt='tagPrice' />
										<span className='font-medium text-sm text-black37'>{item?.offerInfo}</span>
									</div>
								)}
							</div>
							<div
								id='homepage_see_other_outlets'
								className='inline-flex gap-2 items-center py-1.5 px-2.5 bg-ufdPurple/10 text-ufdPurple rounded-full mt-0.5 cursor-pointer min-w-40 justify-between'
								onClick={() => handleOutletByLocation(item)}>
								<div className='w-full flex justify-center'>{isLoadingOtherLocation && selectedStoreID === item?.storeID ? <LoaderIcon /> : 'See other location'}</div>
								<ChevronRightIcon className='text-ufdPurple w-5 h-5' />
							</div>
						</div>
					</div>
				);
			})}
			{!isLoadingOtherLocation && outletByLocation?.length ? (
				<OutletByLocation isOpen={isOpenOtherLocation} outlet={outletByLocation} onClose={setIsOpenOtherLocation} />
			) : !isLoadingOtherLocation ? (
				<NoOutlet isOpen={isOpenOtherLocation} onClose={() => setIsOpenOtherLocation(false)} />
			) : null}
			{isLoadMore && (
				<div className='flex justify-center'>
					<LoaderIcon />
				</div>
			)}
		</div>
	);
};

export default Outlet;
