import React from 'react';
import { ListPaymentMethodAll } from '../../../utils/constants';
import { UltraVoucherIcon } from '../../../utils/svg';

const PaymentMethod = ({ orderDetails = {} }) => {
	const paymentMethod = ListPaymentMethodAll?.find((method) => method?.code === orderDetails?.request_post_datas?.paymentMethod) || {};

	return (
		<div className='p-4 border-solid border-1 border-gray-300'>
			<div className='text-xs text-gray-500 font-normal'>Payment Method</div>
			<div className='flex mt-5 items-center border-solid border-1 border-gray-300 p-4 rounded-lg'>
				{paymentMethod?.code === 'ULTRA-VOUCHER' ? (
					<UltraVoucherIcon />
				) : (
					<img alt={paymentMethod?.code ?? ''} src={paymentMethod?.image_url} className={`${paymentMethod?.image_size_payment || 'w-11'} h-auto object-contain object-center`} />
				)}
				<div className='pl-2 text-sm text-gray-700'>{paymentMethod?.name ?? ''}</div>
			</div>
		</div>
	);
};

export default PaymentMethod;
