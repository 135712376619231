import React, { useEffect, useState } from 'react';
import { formatRupiah, removeHTML } from '../Helpers/SettingHelper';
import '../../tailwind.css';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/solid';
import Notes from '../Notes/index';
import ImageLoader from '../ImageLoader';

const ProductSimple = ({ product = {}, cart = null, handleCart = (data) => {}, setCartItem = (data) => {} }) => {
	const currentCartItem = cart?.item?.find((item) => item.productID === product?.product_id) || null;

	const desc = removeHTML(product?.short_description || '');
	const [total, setTotal] = useState(1);
	const [note, setNote] = useState('');

	const handleClick = () => {
		console.log(total, note);
		const dataCart = {
			productID: product?.product_id,
			quantity: total,
			type: !currentCartItem ? 'add' : 'update',
			productType: product?.product_type === '1' ? 'Simple' : 'Combo',
			specialNotes: note,
			itemID: currentCartItem?.itemID ?? '',
		};
		console.log('ProductSimple handleClick', dataCart);
		handleCart(dataCart);
	};

	useEffect(() => {
		if (!currentCartItem) return;
		setTotal(Number(currentCartItem?.itemQuantity || 1));
		setNote(currentCartItem?.itemNotes || '');
	}, [currentCartItem]);

	return (
		<>
			<div className={`bg-white rounded-t-xl p-4 shadow-custom transition-transform duration-500 ease-in-out ${true ? 'translate-y-0 relative' : 'translate-y-full hidden'}`}>
				<div className='hidden justify-center'>
					<div className='bg-gray-300 h-1.5 w-10 rounded-full' />
				</div>
				<div className='relative mt-4 h-44'>
					<ImageLoader alt={product?.product_slug} src={product?.product_thumbnail} />
				</div>
				<div className='mt-4 text-gray-900 font-semibold text-sm'>{product?.product_name || ''}</div>
				<div className='text-sm flex items-center pt-2'>
					<div className='font-semibold pr-1 text-gray-800'>{formatRupiah(product?.product_price)}</div>
					{Boolean(Number(product?.product_cost)) && <div className='line-through text-gray-500'>{formatRupiah(Number(product?.product_cost))}</div>}
				</div>
				<div className='text-gray-400 text-xs pt-2 font-normal'>{desc}</div>
				<div className='mt-5'>
					<Notes item_id={currentCartItem?.itemID ?? ''} callback={setNote} notes={note} />
				</div>
			</div>
			<div className='bg-white w-full p-4 z-auto shadow-custom'>
				<div className='flex justify-between items-center'>
					<div className='text-gray-900 text-xs font-semibold'>Item quantity</div>
					<div className='bg-white p-1 rounded-md cursor-pointer flex items-center border-1 border-solid border-byzantium text-xs'>
						<MinusIcon
							className='h-5 w-5 text-byzantium'
							onClick={() =>
								setTotal((prev) => {
									if (prev === 0) return 0;
									return prev - 1;
								})
							}
						/>
						<input
              disabled
							type='text'
							className='!bg-white !m-0 !p-0 !h-5 !w-8 text-center text-black font-semibold text-xs'
							value={total}
							onChange={(e) => {
								const value = e.target.value;
								const num = value.replace(/[^0-9]/g, '');
								setTotal(num);
							}}
						/>
						<PlusIcon className='h-5 w-5 text-byzantium' onClick={() => setTotal((prev) => prev + 1)} />
					</div>
				</div>
				<button
					onClick={handleClick}
					disabled={!total}
					className={`mt-2 w-full disabled:cursor-not-allowed  ${!total ? 'bg-gray-400' : 'bg-rose-violet'} text-white flex justify-center font-normal text-xs p-3 items-center border-0`}>
					{!currentCartItem ? 'Add to Cart' : 'Update Cart'}
				</button>
			</div>
		</>
	);
};

export default ProductSimple;
