import React from "react";
import searchIcon from "../../../../common/images/revamp/icon/search.svg";
import closeCircleIcon from "../../../../common/images/revamp/icon/close-circle.svg";
import mapIcon from "../../../../common/images/revamp/icon/map.svg";
import gpsIcon from "../../../../common/images/revamp/icon/gps.svg";
import useDeliveryLocationDetail from "./useDeliveryLocationDetail";
import DeliverySavedAddress from "./SavedAddress";
import DeliveryRecent from "./Recent";
import DeliverySearchResult from "./SearchResult";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const DeliveryLocationDetail = (props) => {
  /*======================== Props ======================== */

  const { handleCloseDelivery, back } = props;
  const {
    isAutocompleteReady,
    search,
    searchData,
    loading,
    setSearch,
    clearSuggestions,
    handleSelectLocation,
    handleCancelSearchSaved,
  } = useDeliveryLocationDetail(props);

  const { savedIsOnSearch, recentData } = useSelector(
    (state) => state.location
  );
  const address = useSelector((store) => store?.user?.address || "");

  return (
    <div className="w-full h-full overflow-y-auto">
      <div className="p-4">
        <div className="flex items-center rounded-full border border-solid border-grayD3 p-2.5 gap-3 mb-2.5">
          <img src={searchIcon} alt="search" />
          <input
            placeholder="Search location"
            name="search"
            className="flex-auto w-full m-0 bg-transparent border-none outline-none"
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            disabled={!isAutocompleteReady}
          />
          {search && (
            <img
              src={closeCircleIcon}
              alt="clear-search"
              onClick={() => {
                setSearch("");
                clearSuggestions();
              }}
            />
          )}
        </div>
        <div className="w-full flex justify-end  mb-4">
          {savedIsOnSearch ? (
            <div
              className="inline-flex gap-1"
              onClick={() => handleCancelSearchSaved()}
            >
              <img src={mapIcon} alt="map" />
              <span className="text-blue text-xs">Select on map</span>
            </div>
          ) : (
            <Link to={back ? `/google-map?back=${back}` : '/google-map'} className="inline-flex gap-1">
              <img src={mapIcon} alt="map" />
              <span className="text-blue text-xs">Select on map</span>
            </Link>
          )}
        </div>

        {!savedIsOnSearch && !search && (
          <div
            className="flex gap-2 5 items-center"
            onClick={handleCloseDelivery}
          >
            <img src={gpsIcon} alt="gps" />
            <div className="grow space-y-0.5">
              <p className="text-black37 text-sm font-semibold">
                Current Location
              </p>
              <p className="text-gray90 text-xxs">{address}</p>
            </div>
          </div>
        )}
      </div>

      <div className="h-2 w-full bg-grayf4 mb-4"></div>

      <div className="space-y-4 mb-4" style={{width: "100%"}}>
        {search || savedIsOnSearch ? (
          <DeliverySearchResult
            {...{ search, searchData, loading, handleSelectLocation }}
          />
        ) : (
          <>
            <DeliverySavedAddress {...{ handleCloseDelivery }} />

            {recentData && recentData.length > 0 && (
              <DeliveryRecent {...{ handleCloseDelivery }} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DeliveryLocationDetail;
