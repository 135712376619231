import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import DetailTransaction from "./DetailTransaction";
import DetailStore from "./DetailStore";
import RatingComponent from "./RatingComponent";
import OrderSummary from "./OrderSummary";
import Tracking from "./Tracking";
import DeliveryLocation from "./DeliveryLocation";
import PaymentMethod from "./PaymentMethod";
import PaymentSummary from "./PaymentSummary";
import FooterAction from "./FooterAction";
import { uniqueID } from "../../../utils/constants";
import { sendAnalyticsCustomEvent, sendAnalyticsPageView } from "../../../utils/helper";
import { getOrderDetail } from "../../../store/reducers/order";
import { encodeValue } from "../../../utils/helper";

function OrderHistorydetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orderType: paramOrderType, orderNumber: paramOrderNumber } =
    useParams();

  const customerID = useSelector((store) => store.user?.customerID);

  const initialOrderType = paramOrderType === "on-process" ? "all" : paramOrderType || "all";
  const initialOrderNumber = paramOrderNumber || "";

  const orderDetails = useSelector((state) => state.order?.detail);
  const loading = orderDetails === null || orderDetails === undefined;
  const orderID = orderDetails && orderDetails.order_primary_id;
  const orderRating = orderDetails && orderDetails.rating;

  useEffect(() => {
    dispatch(getOrderDetail({ 
      unquieid: uniqueID, 
      customerID: encodeValue(customerID),
      orderNumber: paramOrderNumber }));
  }, []);

  useEffect(() => {
    sendAnalyticsPageView("detail_order_session");
  }, []);

  useEffect(() => {
    if (orderDetails) {
      sendAnalyticsCustomEvent("history_detail_order", orderDetails); 
    }
  }, []);

  useEffect(() => {
    if (initialOrderNumber === "") {
      history.push("/history");
    } else {
      getOrderDetail();
    }
  }, [initialOrderNumber, dispatch, history]);

  useEffect(() => {
    if (!loading && !orderDetails) {
      history.push("/history");
    }
  }, [loading, orderDetails, history]);

  return (
    <div className="main-div transaction-details">
      <Header initialOrderType={initialOrderType} />
      {loading ? (
        <div className="content-body rel history-detail">
          <div className="container">
            <div className="voucher-list on-process-list">
              <ul>
                <li>
                  <ContentLoader viewBox="0 0 380 70">
                    <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                    <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                    <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                  </ContentLoader>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="content-body rel history-detail">
          {orderDetails && (
            <>
              <DetailTransaction orderDetails={orderDetails} />
              <DetailStore orderDetails={orderDetails} />
              {orderDetails.orderStatus === "4" && (
                <div className="px-4">
                  <RatingComponent
                    orderID={orderID}
                    orderType={initialOrderType}
                    orderRating={orderRating}
                  />
                </div>
              )}
              <OrderSummary orderDetails={orderDetails} />
              {["1", "2", "3"].includes(orderDetails.orderStatus) && (
                <div className="px-4">
                  <Tracking orderDetails={orderDetails} />
                </div>
              )}
              <DeliveryLocation orderDetails={orderDetails} />
              <PaymentMethod orderDetails={orderDetails} />
              <PaymentSummary orderDetails={orderDetails} />
              <FooterAction orderDetails={orderDetails} />
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default OrderHistorydetails;
