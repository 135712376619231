import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import food from '../../../common/images/food.png';
import favheart from '../../../common/images/favorite-heart.svg';
import favoriteClip from '../../../common/images/favorite.svg';
import { ReactComponent as StarFull } from '../../../common/images/star.svg';
import { ReactComponent as MotorBike } from '../../../common/images/Motorbike.svg';
import { ReactComponent as DiscountTag } from '../../../common/images/discount-2.svg';
import { ReactComponent as Location } from '../../../common/images/location-pin2.svg';
import barrow from '../../../common/images/arrow-left.svg';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import { axiosInstance, encodeValue } from '../../../utils/helper';
import { uniqueID } from '../../../utils/constants';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { setFavorite } from '../../../store/reducers/favorite';
import Loader from '../../Loader/index';
import useLayout from '../../Layout/useLayout';

const FavoriteList = () => {
	const dispatch = useDispatch();
	const storeList = useSelector((state) => state.favorite?.list || []);
	const loading = useSelector((state) => state.favorite.loading);
	const customerID = useSelector((store) => store?.user?.customerID);

	const [open, setOpen] = useState(false);
	const contentRef = useRef(null);
	const [snapPoint, setSnapPoint] = useState(0);

	const latitude = useSelector((state) => state.user?.latitude);
	const longitude = useSelector((state) => state.user?.longitude);

	const { isNearBottom } = useLayout();
	const [totalPage, setTotalPage] = useState(0);
	const [total, setTotal] = useState(1);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [isLoadMore, setIsLoadMore] = useState(false);

	useEffect(() => {
		loadStoreFavorite();
	}, []);

	useEffect(() => {
		if (!isNearBottom || isLoadMore || page >= totalPage) return;
		setIsLoadMore(true);
		const nextPage = page + 1;
		loadStoreFavorite(nextPage, limit, true);
	}, [isNearBottom]);

	useEffect(() => {
		if (open) {
			setTimeout(() => {
				if (contentRef.current) {
					const contentHeight = contentRef.current.getBoundingClientRect().height;
					setSnapPoint(contentHeight);
				}
			}, 0);
		}
	}, [open, storeList]);

	const onDismiss = () => {
		setOpen(false);
	};

	const removeFavourite = async (storeID) => {
		console.log('removeFavourite');
		const postObject = {
			unquieid: uniqueID,
			shopID: encodeValue(storeID),
			customerID: encodeValue(customerID),
		};
		await axiosInstance
			.post(`store/removeFavourite`, qs.stringify(postObject))
			.then((res) => {
				if (res.data.status === 'ok') {
					loadStoreFavorite();
				} else if (res.data.status === 'error') {
					console.log('Remove favorite failed', res.data.message);
				}
			})
			.catch((error) => {
				console.log('Remove favorite failed', error);
			});
	};

	const loadStoreFavorite = useCallback(
		async (page = 1, limit = 10, isLoadMore = false) => {
			try {
				dispatch(
					setFavorite({
						loading: true,
					}),
				);

				const url = `outlets/list-favorite?unquieid=${uniqueID}&latitude=${latitude}&longitude=${longitude}&customerID=${encodeValue(customerID)}&page=${page}&limit=${limit}`;
				const res = await axiosInstance.get(url);
				const { status, result = [], total_pages = 0, page: resPage = 0, total = 0, limit: resLimit = 0 } = res?.data || {};

				if (status === 'ok') {
					const newItems = isLoadMore ? result.filter((item) => !storeList.some((outlet) => outlet.storeID === item.storeID)) : result;

					dispatch(
						setFavorite({
							list: isLoadMore ? [...storeList, ...newItems] : result,
							loading: false,
						}),
					);

					setTotalPage(total_pages);
					setPage(resPage);
					setTotal(Number(total));
					setLimit(resLimit);
					setIsLoadMore(false);
				} else {
					dispatch(
						setFavorite({
							list: isLoadMore ? storeList : [],
							loading: false,
						}),
					);
				}
			} catch (error) {
				console.error('Error while loading favorites: ', error.message || error);
				dispatch(
					setFavorite({
						list: isLoadMore ? storeList : [],
						loading: false,
					}),
				);
			}
		},
		[page, limit, totalPage, latitude, longitude, uniqueID, isLoadMore, customerID, storeList],
	);

	return (
		<div className='main-div'>
			{loading && <Loader overlay />}
			<div className='flex items-center px-4 h-[53px] sticky top-0 z-10 bg-white' style={{ borderBottom: '2px solid #D3D3D3' }}>
				<Link to={`/`} id='back_btn_fav'>
					<img src={barrow} alt='Back' className='w-6 h-6' />
				</Link>
				<h2 className='text-base font-semibold ml-2'>My Favorite</h2>
			</div>
			<div className='content-body rel'>
				<div className='px-4'>
					{!loading && !storeList?.length && (
						<div className='w-full h-[60vh] flex flex-col justify-center items-center px-4'>
							<img src={favoriteClip} alt='Favorite' />
							<h2 className='text-base font-semibold'>There is no favorite restaurant</h2>
							<p className='text-xs font-normal'>Dishes and restaurants you've tap the</p>
							<Link to='/' className='w-full mt-8'>
								<button className='button w-full'>Explore Ufood</button>
							</Link>
						</div>
					)}

					<div className='outlet-listing fav-listing'>
						<ul>
							{storeList?.map((item, index) => (
								<li key={index} id={`store-${item?.storeID}`}>
									<div className='flex' style={{ gap: '11px', marginBottom: '26px' }}>
										<a href={`/outlet/${item?.storeSlug}`}>
											<div style={{ height: '95px', width: '95px' }}>
												<img
													style={{
														minWidth: '95px',
														minHeight: '95px',
														objectFit: 'cover',
														objectPosition: 'center',
														borderRadius: '8px',
													}}
													src={item?.storeImage || food}
													alt={item?.storeName}
												/>
											</div>
										</a>
										<div className='w-full'>
											<a href={`/outlet/${item?.storeSlug}`}>
												<h3 className='text-sm font-semibold'>{item?.storeName}</h3>
												<div className='flex gap-1 pt-1'>
													<StarFull width={14} height={14} color='#FBAA19' />
													<h5 className='text-xs font-normal'>
														{item?.rating || 0} ({item?.totalRating || 0})
													</h5>
												</div>
												<div className='flex gap-1 pt-1'>
													<MotorBike width={14} height={14} />
													<h5 className='text-xs font-normal'>
														{parseFloat(item?.distance || 0).toFixed(2)} km
													</h5>
												</div>
												{item?.offerInfo && (
													<h6 className='flex gap-1 items-center pt-1'>
														<DiscountTag width={14} height={14} />
														{item?.offerInfo && <div className='text-xs font-medium'>{item?.offerInfo}</div>}
													</h6>
												)}
											</a>
										</div>
									</div>
									<div onClick={() => removeFavourite(item.storeID)} className='add-fav'>
										<img src={favheart} alt='Heart' />
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
			<BottomSheet
				open={open}
				onDismiss={onDismiss}
				defaultSnap={({ maxHeight }) => maxHeight / 2}
				snapPoints={({ maxHeight }) => Math.min(snapPoint, maxHeight - maxHeight / 7)}
				header={<h3 className='flex items-start text-base font-semibold py-3'>List of Outlets</h3>}>
				<div ref={contentRef} className='px-4' style={{ marginTop: '12px' }}>
					<ul>
						{storeList?.map((item, index) => (
							<li key={index} className='mb-4'>
								<a href={`/outlet/${item.storeSlug}`}>
									<div className='flex' style={{ gap: '12px' }}>
										<img
											src={item?.storeImage || food}
											alt={item?.storeName}
											style={{
												width: '24px',
												height: '24px',
												objectFit: 'cover',
												borderRadius: '2px',
											}}
										/>
										<div style={{ maxWidth: 'calc(100% - 36px)', marginBottom: '14px' }}>
											<h4 className='text-sm font-semibold'>{item?.storeName}</h4>
											<div className='flex gap-1'>
												<Location width={14} height={14} />
												<p className='text-xs font-normal'>{parseFloat(item?.distance || 0).toFixed(2)} km away</p>
											</div>
										</div>
									</div>
								</a>
							</li>
						))}
					</ul>
				</div>
			</BottomSheet>
		</div>
	);
};

export default FavoriteList;
