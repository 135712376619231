import { useEffect, useRef } from 'react';
import '../../tailwind.css';

const Footer = (props) => {
	const { id = '', children, position = 'absolute', onTouchOutside = () => {} } = props;
  const footerRef = useRef(null);

  useEffect(() => {
    const handleTouchOutside = (event) => {
      // Jika klik di luar elemen footer, lakukan tindakan yang diinginkan di sini
      if (footerRef.current && !footerRef.current.contains(event.target)) {
        onTouchOutside()
      }
    };

    document.addEventListener('mousedown', handleTouchOutside);
    document.addEventListener('touchstart', handleTouchOutside);

    return () => {
      document.removeEventListener('mousedown', handleTouchOutside);
      document.removeEventListener('touchstart', handleTouchOutside);
    };
  }, [onTouchOutside]);

	return (
		<div id={id} ref={footerRef} className={`${position} w-full bottom-0 left-0 z-40`}>
      {children}
		</div>
	);
};

export default Footer;
