import React, { useState } from 'react';
import { formatRupiah } from '../../components/Helpers/SettingHelper';
import '../../tailwind.css';
import { StarIcon, PlusIcon, MinusIcon } from '@heroicons/react/24/solid';
import FoodImg from '../../common/images/food.png';
import { PencilEditIcon } from '../../utils/svg';

const CartDetail = (props) => {
	const {
		data = {},
		handleGetProductDetail = () => {},
		handleCart = (data) => {},
	} = props;

	// const currentCartItem = cart?.item?.find((item) => `${item.productID}_` === id) || null;
	const [imageLoadStatus, setImageLoadStatus] = useState('loaded');

	const handleClick = async (productType, type, productID) => {
		console.log('ProductDetail handleClick');
		// 1 = simple product
		// 2 = combo product
		let prevTotal = Number(data?.itemQuantity);
		if (type === 'plus') {
			prevTotal = prevTotal + 1;
		} else {
			prevTotal = prevTotal !== 0 ? prevTotal - 1 : 0;
		}

		const dataCart = {
			productID: productID,
			quantity: prevTotal,
			type: 'update',
			productType: productType === '1' ? 'Simple' : 'Combo',
			itemID: data?.itemID || null,
		};

		handleCart(dataCart);
	};
	return (
		<>
			<div key={`${data?.itemID}`} className={`flex mb-4`}>
				<div className={`aspect-[1/1] relative w-24 h-24`}>
					{imageLoadStatus === 'loaded' ? (
						<img
							alt={data?.itemImage}
							src={data?.itemImage}
							onLoad={() => {
								setImageLoadStatus('loaded');
							}}
							onError={() => {
								setImageLoadStatus('error');
							}}
							className='h-full w-full rounded-xl object-cover object-center'
						/>
					) : (
						<img alt={data?.itemImage} src={FoodImg} className='h-full w-full rounded-xl object-cover object-center' />
					)}
				</div>
				<div className='pl-3 w-full'>
					<div className='text-gray-800 text-base font-semibold'>{data?.itemName}</div>
					<div className={`${data?.comboset?.length && 'pt-1'}`}>
						{data?.comboset?.map((combo, index) => {
							return (
								<div key={index} className='flex flex-col'>
									<div className='text-gray-800 text-xs font-semibold pr-1'>{`${combo?.comboSetname} :`}</div>
									{combo?.productDetails?.map((product, idx) => {
										return (
											<div key={`${index}_${idx}`} className='flex'>
												<div className='text-gray-800 text-xs font-normal'>{product?.productName}</div>
												{Boolean(Number(product?.productPrice || 0)) && <div className='text-gray-500 text-xs font-normal pl-1'>{`+${formatRupiah(product?.productPrice)}`}</div>}
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
					<div className='flex pt-1 flex-wrap'>
						<div className='text-gray-800 text-xs font-semibold'>Notes:</div>
						<div className='text-gray-800 font-normal text-xs pl-1'>{data?.itemNotes ?? '-'}</div>
					</div>
					<div className='text-sm flex items-center pt-1'>
						<div className='font-semibold pr-1 text-gray-800'>{formatRupiah(data?.itemTotalPrice)}</div>
						{/* {Boolean(strikeOutPrice) && <div className='line-through text-gray-500'>{formatRupiah(strikeOutPrice)}</div>} */}
					</div>
					<div className='flex justify-between mt-2'>
						<div
							className='flex border-1 border-solid border-gray-300 rounded-full py-1 px-2 items-center'
							onClick={() => handleGetProductDetail(data?.productID, data)}>
							<PencilEditIcon />
							<div className='pl-2 text-gray-900 text-xs font-semibold'>Edit</div>
						</div>
						<div className=''>
							{Number(data?.itemQuantity || 0) ? (
								<div className='bg-white p-1 rounded-md cursor-pointer flex items-center border-solid border-1 border-byzantium'>
									<MinusIcon className='h-5 w-5 text-byzantium' onClick={() => handleClick(data?.productType, 'minus', data?.productID)} />
									<input
										type='text'
										className='!bg-white !m-0 !p-0 !h-5 !w-8 text-center !text-byzantium font-normal text-xs'
										value={Number(data?.itemQuantity || 0)}
										disabled
										// onChange={(e) => {
										// 	const value = e.target.value;
										// 	const num = value.replace(/[^0-9]/g, '');
										// 	// setTotal(num);
										// }}
									/>
									<PlusIcon className='h-5 w-5 text-byzantium' onClick={() => handleClick(data?.productType, 'plus', data?.productID)} />
								</div>
							) : (
								<div className='bg-white p-1 rounded-md cursor-pointer border-solid border-1 border-byzantium' onClick={() => handleGetProductDetail(data?.productID)}>
									<PlusIcon className='h-5 w-5 text-byzantium' />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CartDetail;
