import React from 'react';
import copy from '../../../common/images/copy-02.svg';
import useToast from '../../Toast';
import { CopyIcon } from '../../../utils/svg';
import { formatDate } from '../../../utils/helper';
import { OrderStatusList } from '../../../utils/constants';

const DetailTransaction = ({ orderDetails = {} }) => {
	const { showToast } = useToast();

	const copyToClipboard = (text) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				console.log('copy', text);
				showToast('info', 'copied');
			})
			.catch((err) => {
				console.error('Failed to copy: ', err);
			});
	};

	const statusDetails = OrderStatusList?.[orderDetails?.orderStatus] ?? {};

	return (
		<div className='px-4'>
			<h2 className='text-sm font-semibold'>Detail Transaction</h2>
			<div className='flex justify-between mt-4'>
				<p className='text-xs font-normal'>Order Number</p>
				<div className='flex gap-2'>
					<div className='flex items-center cursor-pointer' onClick={() => copyToClipboard(orderDetails?.orderNumber)}>
						<p className='text-xs font-semibold text-gray-900 pr-2'>{orderDetails?.orderNumber}</p>
						<CopyIcon />
					</div>
				</div>
			</div>
			<div className='flex justify-between mt-2'>
				<p className='text-xs font-normal'>Purchased on</p>
				<p className='text-xs font-semibold text-gray-900'>{formatDate(orderDetails?.order_date, 'D MMM YYYY, HH:MM A')}</p>
			</div>
			<div className='flex justify-between mt-2'>
				<p className='text-xs font-normal'>Status</p>
				<div className='text-xs font-semibold'>
					<div className={statusDetails?.className}>{statusDetails?.message}</div>
				</div>
			</div>
		</div>
	);
};

export default DetailTransaction;
