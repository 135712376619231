export const GET_SEARCH_OUTLET = "search/get-outlet";
export const SET_SEARCH_OUTLET = "search/set-outlet";
export const GET_LOADING_OUTLET = "search/get-loading-outlet";
export const SET_LOADING_OUTLET = "search/set-loading-outlet";
export const GET_SEARCH_POPULAR = "search/get-popular";
export const SET_SEARCH_POPULAR = "search/set-popular";
export const GET_LOADING_POPULAR = "search/get-loading-popular";
export const SET_LOADING_POPULAR = "search/set-loading-popular";
export const GET_SEARCH_RECOMMENDATION = "search/get-recommendation";
export const SET_SEARCH_RECOMMENDATION = "search/set-recommendation";
export const GET_LOADING_RECOMMENDATION = "search/get-loading-recommendation";
export const SET_LOADING_RECOMMENDATION = "search/set-loading-recommendation";
export const GET_SEARCH_CART = "search/get-cart";
export const SET_SEARCH_CART = "search/set-cart";