import React from 'react';
import shop from '../../../common/images/shop-ilustration.svg';
import locationPin from '../../../common/images/location-pin.svg';

const DeliveryLocation = ({ orderDetails = {} }) => {

	return (
		<div className='px-4'>
			<div className='flex items-center'>
				<img src={shop} alt='Shop' />
				<div className='ml-3'>
					<p className='text-xs text-gray-500 font-normal'>Restaurant Location</p>
					<p className='text-sm text-gray-800 font-semibold pt-1'>{orderDetails?.orderItems?.[0]?.storeName}</p>
				</div>
			</div>
			<div className='flex items-center my-5'>
				<img src={locationPin} alt='Location' />
				<div className='ml-3 w-full pr-10'>
					<p className='text-xs text-gray-500 font-normal'>Delivery Location</p>
					<p className='text-sm text-gray-800 font-semibold truncate pt-1'>{orderDetails?.address}</p>
				</div>
			</div>
		</div>
	);
}

export default DeliveryLocation;
