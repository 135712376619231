import useSearch from './useSearch';
import SearchNotFound from './SearchNotFound';
import SearchList from './List';
import SeachHeader from './Header';
import star from '../../common/images/revamp/icon/star.svg';
import Loader from '../Loader';
import ImageLoader from '../ImageLoader';
import { sendAnalyticsPageView } from '../../utils/helper';
import { useEffect } from 'react';

const Search = () => {
	const { popular, recommendation, outlets, query, handleSearch, isLoading, inputRef, isWaiting } = useSearch();

  useEffect(() => {
    sendAnalyticsPageView("search_food_session");
  }, []);

	return (
		<>
			{isLoading && <Loader overlay />}
			<div className='search'>
				<SeachHeader {...{ query, handleSearch, inputRef }} />

				{!query && (
					<div className='px-4 py-3'>
						{popular && popular.length > 0 && (
							<div className='hidden'>
								<p className='mb-3 text-black37 font-semibold capitalize'>Popular Search</p>
								<div className='flex flex-wrap gap-3 mb-8'>
									{popular?.map((item, index) => (
										<div
											key={index}
											className='cursor-pointer rounded-full bg-[#7327681A] text-ufdPurple px-2.5 py-1.5 font-medium'
											onClick={() => {
												console.log('onClick', item);
												handleSearch(item, true);
											}}>
											{item}
										</div>
									))}
								</div>
							</div>
						)}

						{recommendation && recommendation.length > 0 && (
							<>
								<p className='mb-3 text-black37 font-semibold capitalize'>Recommended Brand You Maybe Like</p>

								<div className='grid gap-3 grid-cols-3 mb-4'>
									{recommendation?.map((item, index) => (
										<div key={index} className=''>
											<div className='w-32 h-32 p-0.5'>
												<ImageLoader src={item.storeImage} alt={item.storeImage} className='rounded-lg w-full h-full border-1 border-solid border-gray-200 object-center aspect-square' />
											</div>
											<p className='mb-0 font-semibold text-black37 max-w-[calc(100vw-139px)] line-clamp-2'>{item.storeName}</p>
											<div className='flex items-center gap-2.5'>
												<div className='flex items-center gap-1'>
													<span>{item.distance !== '' ? parseFloat(item.distance).toFixed('2') : '0'} km</span>
												</div>

												<div className='flex items-center gap-1'>
													<img src={star} alt='star' />
													<span className='font-medium text-sm text-black37'>{item.rating || item.Rating || 0}</span>
												</div>
											</div>
										</div>
									))}
								</div>
							</>
						)}
					</div>
				)}
				{query && !isWaiting ? outlets?.length ? <SearchList {...{ outlets }} /> : !isLoading ? <SearchNotFound /> : null : null}
			</div>
		</>
	);
};

export default Search;
