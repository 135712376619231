import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	outlet_id: null,
	product: [],
	outlet: null,
	cart: null,
	cartItem: null,
	item: null,
	notes: null,
	loading: false,
	simple: null,
	combo: null,
	delivery: null,
	pathnersList: [],
};

const cart = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		setCart: (state, action) => {
			return { ...state, ...action.payload };
		},
		clearCart: () => {
			return initialState;
		},
	},
});

// Export actions and reducer
export const { setCart, clearCart } = cart.actions;
export default cart.reducer;
