import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	popular: [],
	recomendation: [],
	loading: false,
};

const search = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setSearch: (state, action) => {
			return { ...state, ...action.payload };
		},
		clearSearch: () => {
			return initialState;
		},
	},
});

// Export actions and reducer
export const { setSearch, clearSearch } = search.actions;
export default search.reducer;
