import React from "react";
import notFound from "../../common/images/revamp/images/search-not-found.svg";

const SearchNotFound = () => {
  return (
    <div className="px-4 flex flex-col justify-center items-center">
      <img src={notFound} alt="Not Found" className="w-3/5" />
      <p className="font-semibold text-lg text-black37 mb-1">
        Hmm, no restaurant found
      </p>
      <p className="text-sm text-black37 text-center px-14">
        We couldn't locate what you're looking for. How about trying something
        else?
      </p>
    </div>
  );
};

export default SearchNotFound;
