import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  all: [],
  received: [],
  delivering: [],
  processing: [],
  completed: [],
  canceled: [],
  detail: null,
  loading: false,
  params: {
    customerID: null,
    unquieid: null,
    orderType: null,
  },
};

const order = createSlice({
	name: 'order',
	initialState,
	reducers: {
		setOrder: (state, action) => {
			return { ...state, ...action.payload };
		},
		clearOrder: () => {
			return initialState;
		},
	},
});

export const { setOrder, clearOrder } = order.actions;
export default order.reducer;
