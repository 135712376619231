import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../utils/helper';
import { encodeValue } from '../../utils/helper';

const initialState = {
  list: [],
  loading: false,
  totalPages: 0,
  currentPage: 0,
  total: 0,
  limit: 0
};

export const loadStoreFavorite = createAsyncThunk(
  'favorite/loadStoreFavorite',
  async ({ page = 1, limit = 10, isLoadMore = false, uniqueID, latitude, longitude, customerID }, { getState }) => {
    const url = `outlets/list-favorite?unquieid=${uniqueID}&latitude=${latitude}&longitude=${longitude}&customerID=${encodeValue(customerID)}&page=${page}&limit=${limit}`;
    const response = await axiosInstance.get(url);
    const { status, result = [], total_pages = 0, page: resPage = 0, total = 0, limit: resLimit = 0 } = response?.data || {};
    
    const currentState = getState().favorite;
    
    if (status === 'ok') {
      const newItems = isLoadMore 
        ? result.filter(item => !currentState.list.some(outlet => outlet.storeID === item.storeID))
        : result;

      return {
        list: isLoadMore ? [...currentState.list, ...newItems] : result,
        totalPages: total_pages,
        currentPage: resPage,
        total: Number(total),
        limit: resLimit,
        isLoadMore
      };
    }
    throw new Error('Failed to load favorites');
  }
);

const favorite = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    clearFavorite: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadStoreFavorite.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadStoreFavorite.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.list;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
        state.total = action.payload.total;
        state.limit = action.payload.limit;
      })
      .addCase(loadStoreFavorite.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const { clearFavorite } = favorite.actions;
export default favorite.reducer;