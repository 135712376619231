import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	latitude: null,
	longitude: null,
	city: null,
	village: null,
	state: null,
	country: null,
	address: null,
	primary_phone: null,
	firstName: null,
	lastName: null,
	email: null,
	postalCode: null,
	district: null,
	customerID: null,
	customerUVID: null,
	token: null,
	refresh_token: null,
};

const user = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action) => {
			return { ...state, ...action.payload };
		},
		clearUser: () => {
			return initialState;
		},
	},
});

export const { setUser, clearUser } = user.actions;
export default user.reducer;
