import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axiosInstance";

const initialState = {
  data: [],
  selected: null,
  loading: false,
};

export const getTagList = createAsyncThunk("tag/getTagList", async (params) => {
  const res = await axiosInstance.get("store/tagList", { params });
  const data = await res.data;
  return data;
});

const tag = createSlice({
  name: "tag",
  initialState,
  reducers: {
    setTag: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearTag: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTagList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTagList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.result;
      });
  },
});

// Export actions and reducer
export const { setTag, clearTag } = tag.actions;
export default tag.reducer;
