/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryList } from "../../../store/reducers/category";

const useCategory = () => {
  /*======================== Store ======================== */

  const dispatch = useDispatch();
  const { data: categories, loading } = useSelector((state) => state.category);

  /*======================== UseState ======================== */

  const [selected, setSelected] = useState([]);

  /*======================== Handler ======================== */

  const handleSelect = (value) => {
    if (selected.includes(value)) {
      setSelected(selected.filter((item) => item !== value));
    } else {
      setSelected([...selected, value]);
    }
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    dispatch(getCategoryList());
  }, []);

  /*======================== Return ======================== */

  return {
    categories,
    selected,
    loading,
    handleSelect,
  };
};

export default useCategory;
