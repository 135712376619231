import { useEffect, useState } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useSelector } from 'react-redux';
import { encodeValue } from '../../components/Helpers/SettingHelper';

const VerifyPin = ({ onClose = () => {}, onSubmit = (pin = '', onValidate = false) => {}, isInvalid = false, onClear = () => {} }) => {
	const user = useSelector((store) => store?.user);

	const [pin, setPin] = useState(new Array(6).fill(''));

	const handlePinChange = (value, index) => {
		console.log('handlePinChange');
		if (/^[0-9]$/.test(value)) {
			const newPin = [...pin];
			newPin[index] = value;
			setPin(newPin);

			if (index < 5 && value) {
				document.getElementById(`pin-${index + 1}`).focus();
			}
		} else if (value === '') {
			const newPin = [...pin];
			newPin[index] = value;
			setPin(newPin);
		}
	};

	const handleBackspace = (e, index) => {
		console.log('handleBackspace');
		onClear();
		if (e.key === 'Backspace' && !pin[index] && index > 0) {
			document.getElementById(`pin-${index - 1}`).focus();
		}
	};

	useEffect(() => {
		if (pin.every((digit) => digit !== '') && user?.token) {
			setTimeout(() => {
				console.log('PIN is complete:', pin.join(''));
				onSubmit(encodeValue(pin.join('')), true);
			}, 1000);
		}
	}, [pin, user?.token]);

	return (
		<div className='h-screen w-full flex justify-center items-center'>
			<div className='relative h-screen w-full max-w-lg'>
				<div className={`absolute mt-5 w-full max-w-lg`}>
					<div className='flex flex-row justify-between mx-4'>
						<div className='flex justify-center items-center cursor-pointer' onClick={onClose}>
							<ArrowLeftIcon className='h-8 w-8 text-gray-900 bg-white rounded-full p-1.5' />
						</div>
					</div>
					<div className='bg-white mt-4 px-5 flex flex-col'>
						<div className=''>
							<div className='font-bold text-gray-800 text-2xl'>PIN</div>
							<div className='pt-2 text-gray-500 font-normal'>The PIN will be required for transactions using Ultra Value and Points.</div>
						</div>
						<div className='mt-8 flex justify-center gap-1 xxs:gap-2'>
							{pin?.map((digit, index) => {
								return (
									<input
										key={index}
										id={`pin-${index}`}
										type='number'
										maxLength='1'
										value={digit}
										onChange={(e) => handlePinChange(e.target.value, index)}
										onKeyDown={(e) => handleBackspace(e, index)}
										className='w-full h-full text-center border-1 border-solid border-gray-400 bg-white rounded-lg text-2xl font-semibold text-gray-700 py-4'
									/>
								);
							})}
						</div>
						<div className={`${!isInvalid && 'hidden'} mt-5 text-red-500 text-base`}>You entered the wrong PIN</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VerifyPin;
