import React from "react";
import { Link } from "react-router-dom";
import useHeader from "./useHeader";
import DeliveryLocation from "../DeliveryLocation";

const Header = () => {
  /*======================== Props ======================== */

  const { headerMenu } = useHeader();

  /*======================== Return ======================== */

  return (
    <>
      <div className="w-full flex justify-between items-center gap-2.5">
        <DeliveryLocation />
        <div className="flex gap-2.5 shrink-0">
          {headerMenu.map((menu) => (
            <Link key={menu.name} to={menu.to}>
              <img
                className="w-8 h-8 grid place-content-center rounded-full bg-white/20 cursor-pointer"
                src={menu.icon}
                alt={menu.name}
              />
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Header;
