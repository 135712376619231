import React, { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import FoodImg from '../../common/images/food.png';

const preloadImageWithErrorHandling = (src, fallbackSrc) => {
	return new Promise((resolve) => {
		const img = new Image();
		img.src = src;
		img.onload = () => resolve(src);
		img.onerror = () => {
			resolve(fallbackSrc);
		};
	});
};

const ImageLoader = ({ src = '', alt = '' }) => {
	const [imageSrc, setImageSrc] = useState(null);

	useEffect(() => {
		const loadImage = async () => {
			try {
				const loadedImageSrc = await preloadImageWithErrorHandling(src, FoodImg);
				setImageSrc(loadedImageSrc);
			} catch (error) {
				console.error('Error loading image: ', error);
				setImageSrc(FoodImg);
			}
		};

		loadImage();
	}, [src]);

	return (
		<>
			{!imageSrc ? (
				<ContentLoader speed={2} width='100%' height='100%' backgroundColor='#f3f3f3' foregroundColor='#ecebeb'>
					<rect x='0' y='0' rx='10' ry='10' width='100%' height='100%' />
				</ContentLoader>
			) : (
				<img alt={alt} src={imageSrc} className='h-full w-full rounded-xl object-cover object-center' />
			)}
		</>
	);
};

export default ImageLoader;
