import React from 'react';
import motorBike from '../../../common/images/Motorbike.svg';

function DetailStore({ orderDetails }) {
	return (
		<div className='flex px-4 mt-6 mb-8'>
			<img
				src={orderDetails?.orderItems?.[0]?.storeImage}
				alt={orderDetails?.orderItems?.[0]?.storeName}
				style={{
					width: '60px',
					height: '60px',
					borderRadius: '8px',
				}}
			/>
			<div className='pl-3'>
				<h3 className='text-base font-semibold'>{orderDetails?.orderItems?.[0]?.storeName}</h3>
				<p className='text-xs font-normal'>Food and Beverage</p>
				<div className='flex gap-1 items-center mt-1'>
					<img src={motorBike} alt='Motorbike' />
					<p className='text-xs font-semibold'>0.8 km</p>
				</div>
			</div>
		</div>
	);
}

export default DetailStore;
