import React, { useEffect } from "react";
import arrow from "../../../common/images/revamp/icon/arrow-black.svg";
import chevron from "../../../common/images/revamp/icon/chevron-black.svg";
import tnc from "../../../common/images/revamp/icon/tnc.svg";
import description from "../../../common/images/revamp/icon/description.svg";
import clock from "../../../common/images/revamp/icon/clock.svg";
import { formatRupiah } from "../../Helpers/SettingHelper";
import useVoucherDetail from "./useVoucherDetail";
import { twMerge } from "tailwind-merge";
import moment from "moment";
import FoodImg from '../../../common/images/food.png'

const VoucherDetail = ({ data, onClose }) => {
  console.log('data: ', data);
  /*======================== Props ======================== */

  const { expand, handleExpand } = useVoucherDetail();

  /*======================== Return ======================== */

  return (
    <>
      <div className="fixed z-[100000] w-screen h-screen top-0 left-0 bg-white pointer-events-auto">
        <div className="p-2.5 flex items-center gap-1.5 border-b border-solid border-grayD3">
          <img
            src={arrow}
            alt="arrow-left w-8 h-8 cursor-pointer"
            onClick={onClose}
          />
          <span className="text-black37 font-semibold capitalize">
            Voucher Detail
          </span>
        </div>
        <div className="p-4">
          <div className="w-full shadow-custom rounded-[10px] p-4 flex items-center gap-4 mb-4">
            <img
              src={data?.image?.url || data?.image || FoodImg}
              alt="voucher-image"
              className="w-16 h-16 rounded-md"
            />
            <div className="space-y-1">
              <p className="font-medium text-xs text-gray64">{data.name}</p>
              <div className="flex items-center gap-0.5">
                <span className="text-gray90 font-medium text-xxs">
                  Total Nominal:
                </span>
                <span className="text-ufdPurple font-semibold text-sm">
                  {formatRupiah(data?.nominal || data?.nominal_voucher || 0)}
                </span>
              </div>

              <div className="flex items-center gap-1">
                <img src={clock} alt="clock" />
                <span className="text-gray64 font-bold text-xxs">
                  Exp Date: {moment().format("DD MMM YYYY")}
                </span>
              </div>
            </div>
          </div>

          <div className="mb-2 rounded-s border border-solid border-graye0 transition-all w-full h-full">
            <div
              className="px-4 py-2 hover:bg-grayD3/20 flex justify-between items-center gap-5 transition-all"
              onClick={() => handleExpand("desc")}
            >
              <div className="flex gap-2 items-center">
                <img src={description} alt="description" />
                <div className="[&>*]:mb-0">
                  <p className="font-semibold text-xs text-black37">
                    Description
                  </p>
                  <p className="text-xxs text-gray90">
                    Click to see the voucher description
                  </p>
                </div>
              </div>
              <img
                src={chevron}
                alt="chevron"
                className={twMerge(
                  "transition-all -rotate-180",
                  expand === "desc" && "rotate-0"
                )}
              />
            </div>
            <p
              className={twMerge(
                "h-0 px-3 pt-1 pb-3 hidden m-0 transition-all",
                expand === "desc" && "h-full block"
              )}
            >
              {data.description}
            </p>
          </div>

          <div className="rounded-s border border-solid border-graye0 transition-all w-full h-full">
            <div
              className="px-4 py-2 hover:bg-grayD3/20 flex justify-between items-center gap-5 transition-all"
              onClick={() => handleExpand("tnc")}
            >
              <div className="flex gap-2 items-center">
                <img src={tnc} alt="tnc" />
                <div className="[&>*]:mb-0">
                  <p className="font-semibold text-xs text-black37">
                    Terms & Condition
                  </p>
                  <p className="text-xxs text-gray90">
                    Click to see the terms and condition
                  </p>
                </div>
              </div>
              <img
                src={chevron}
                alt="chevron"
                className={twMerge(
                  "transition-all -rotate-180",
                  expand === "tnc" && "rotate-0"
                )}
              />
            </div>
            <p
              className={twMerge(
                "h-0 px-3 pt-1 pb-3 hidden m-0 transition-all",
                expand === "tnc" && "h-full block"
              )}
            >
              {data.tnc}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default VoucherDetail;
