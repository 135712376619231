import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import user from './reducers/user';
import banner from './reducers/banner';
import tag from './reducers/tag';
import outlet from './reducers/outlet';
import order from './reducers/order';
import category from './reducers/category';
import favorite from './reducers/favorite';
import search from './reducers/search';
import cart from './reducers/cart';
import product from './reducers/product';
import voucher from './reducers/voucher';
import location from "./reducers/location";
import payment from "./reducers/payment";

const persistConfig = {
  key: "ufood-widget",
  storage,
};

const rootReducer = combineReducers({
  location,
  user,
  outlet,
  banner,
  tag,
  order,
  category,
  favorite,
  search,
  cart,
  product,
  voucher,
  payment,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "persist/REGISTER",
          "persist/REHYDRATE",
        ],
      },
    }),
});

const persistor = persistStore(store);

// persistor.purge();

export { store, persistor };
