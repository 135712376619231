import { twMerge } from 'tailwind-merge';
import useTags from './useTags';
import { MathRandom } from '../../../utils/random';

const Tags = () => {
	const { tags, loading, selected, handleSelect } = useTags();

	return (
		<div className='w-full mb-3'>
			<div className='grid grid-flow-col auto-cols-min overflow-x-auto snap-x snap-mandatory scroll-smooth scroll-px-4 px-4 gap-2 hide-scroll'>
				{tags?.length === 0 &&
					loading &&
					new Array(6)
						.fill('')
						.map((item, index) => <div key={index} className={`snap-start rounded-full bg-grayD3 h-[33px] animate-pulse`} style={{ width: `${MathRandom(60, 120)}px` }}></div>)}
				{tags?.map((item, index) => (
					<div
						key={index}
						className={twMerge('snap-start w-max cursor-pointer rounded-full bg-[#f4f4f4] px-3 py-1.5 text-[#646464]', selected === item?.value && 'bg-[#7327681A] text-ufdPurple')}
						onClick={() => handleSelect(selected === item?.value ? null : item?.value)}>
						<span className='text-center text-nowrap text-xs'>{item?.label}</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default Tags;
