import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	list: [],
	// brand: null,
	outletBrand: {},
	details: {},
	tags: {},
	products: {},
	// outletByLocation: [],
	// loading: false,
	// params: {
	//   tags: null,
	//   foodtype: null,
	//   page: 1,
	//   limit: 10,
	// },
};

const outlet = createSlice({
	name: 'outlet',
	initialState,
	reducers: {
		setOutlet: (state, action) => {
			return { ...state, ...action.payload };
		},
		setOutletBrand: (state, action) => {
			const { brandID, outlets } = action.payload;

			return {
				...state,
				outletBrand: {
					...state.outletBrand,
					[brandID]: outlets,
				},
			};
		},
		setOutletDetail: (state, action) => {
			const { storeID, detail } = action.payload;
			return {
				...state,
				details: {
					...state.details,
					[storeID]: detail,
				},
			};
		},
		setOutletTags: (state, action) => {
			const { storeID, tag } = action.payload;
			return {
				...state,
				tags: {
					...state.tags,
					[storeID]: tag,
				},
			};
		},
		setOutletProducts: (state, action) => {
			const { storeID, product } = action.payload;
			return {
				...state,
				products: {
					...state.products,
					[storeID]: product,
				},
			};
		},
		clearOutlet: () => {
			return initialState;
		},
	},
});

// Export actions and reducer
export const { setOutlet, clearOutlet, setOutletBrand, setOutletDetail, setOutletTags, setOutletProducts } = outlet.actions;
export default outlet.reducer;
